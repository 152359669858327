// ------------------------------------------------
// Define Variables
// ------------------------------------------------

// Colors
$principalBlue: #0076CF;
$principalPrimaryAlt: #002855;
$principalWarning: #c06f18;
$principalDanger: #96282d; // old f05948
$principalSuccess: #0076CF;
$principalDarkMidnight: #002855;
$principalDarkBlue: #003865;

$simplyPurple: #002855;
$simplyDark: #002855;
$simplyBlue: #0076CF;
$simplyGreen: #0091DA;
$simplyDusty: #979797;

$white: #fff;
$snow: #f7f7f7;
$catskill: #e9edf4;
$iron: #e6e7e8;
$ash: #b2beb5;
$charcoal: #464646;
$black: #000;
$gray: #c8ccce;
$primary: $simplyBlue;
$secondary: $simplyPurple;
$success: $simplyGreen;
$info: #5bc0de;
$warning: $principalWarning;
$danger: $principalDanger;
$lightgray: #e6e7e8;
$darkblue: #111c2f;
$lightPurple: #63648f;

$copy: $charcoal;

// Screen Sizes
// ------------------------------------------------

$screen-xs: 0;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xlg: 1442px; // 1502px
$screen-xxlg: 1600px;
$screen-xxxlg: 1900px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px // 1140px,,,
) !default;

$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $copy;
$headings-color: $copy;
$line-height-base: 1.5; // should be about 36px
$headings-line-height: 1.2; // should be about 48px

// Links
//
// Style anchor elements.

$link-color: $simplyBlue;
$link-hover-color: lighten($link-color, 15%);
$link-decoration: underline;

// Font Families
// ------------------------------------------------
$baseFont: 'Elliot';
$font-family-sans-serif: $baseFont; // used incase of bootstrap
$font-family-base: $baseFont; // used incase of bootstrap

// Font sizes
$font-size-base: 1rem; // 16
$font-size-lg: $font-size-base * 1.085; // 26px
$font-size-sm: $font-size-base * 0.835; // 18.5px
$font-size-xs: $font-size-base * 0.575; // 12.5px

$font-size-regular: 1rem; // 16px

$h1-font-size: $font-size-base * 2.5; // 60px!!!!! (Minor scale 2.488)
$h2-font-size: $font-size-base * 1.085; // 28px (Minor scale 2.074em 49.77px)
$h3-font-size: $font-size-base * 1.7; // 40px (Yep. I'm bigger than H2 • Minor scale 1.728em 41.47px)
$h4-font-size: $font-size-base * 1.34; // 32px (Minor scale 1.44em 34.56px)
$h5-font-size: $font-size-base * 1.25; // 30px (Minor scale 1.2em 28.80px)
$h6-font-size: $font-size-base; // 24px (Base)

$headings-font-weight: bolder;

// Goofy crazy one off font sizes
// $display1-size: 6rem;
// $display2-size: 5.5rem;
$display3-size: 3rem;
$display4-size: 22px;

// Spacing
$button-padding-y: 0.625rem;
$button-padding-x: 1.5rem;

$border-radius-default: 30px;
$border-radius-btn-default: 30px;

$box-shadow-default: 0px 0px 25px rgba($black, 0.2);

$modal-content-border-color: transparent;
$modal-content-border-radius: 20px;
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;

$easing-global: cubic-bezier(0.37, 0.72, 0.52, 1.15);

// Spacing
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25), // 4
    2: ($spacer * .5), // 8
    3: $spacer, // 16
    4: ($spacer * 1.5), // 24
    5: ($spacer * 3), // 48
    6: ($spacer * 4.688), // 75
    7: ($spacer * 5), // 80
    8: ($spacer * 6.875), // 110
  ),
  $spacers
);
