.roi-input {
  &-border-right {
    @media (min-width: $screen-md) {
      border-right: 1px solid #111c2f;
    }
  }
  &-border-bottom {
    @media (max-width: $screen-md) {
      border-bottom: 1px solid #111c2f;
    }
  } 
  &-card-border-bottom {
    @media (max-width: $screen-md) and (min-width: $screen-sm) {
      border-bottom: 1px solid #e6e7e8;
    }
    @media (min-width: $screen-xl) {
      border-bottom: 1px solid #e6e7e8;
    }
  }
  &-card-border-right {
    @media (max-width: $screen-lg) and (min-width: $screen-md) {
      border-right: 1px solid #e6e7e8;
    }
  }
}
