@import './Utilities/context';
@import './Utilities/masonry';

.anchor-link {
  color: $simplyPurple;
  text-decoration: none;
}

.anchors-light a {
  color: lighten($simplyPurple, 50);
}

.rounded-3 {
  border-radius: 2.25rem;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.pim_lead {
  color: $simplyDusty;
}
.btn--inline {
  display: inline;
  line-height: inherit;
  vertical-align: inherit;
}
.btn-square {
  background: #4caac6;
  color: white;
  font-size: 22px;
  z-index: 1032;
  border-radius: 10px;
  width: 25%;
  padding: 1rem;
  font-family: 'Elliot-Regular', sans-serif;
  position: relative;
  cursor: pointer;
}

.btn-square:hover {
  background: #4caac6;
  color: white;
  font-size: 22px;
  font-family: 'Elliot-Regular', sans-serif;
  border-radius: 10px;
  width: 50%;
  padding: 1rem;
}

.btn-next-capsule {
  background: #4caac6;
  color: white;
  font-size: 22px;
  z-index: 1032;
  border-radius: 50px;
  width: 20%;
  padding: 1rem;
  font-family: 'Elliot', sans-serif;
  font-weight: normal;
  font-style: normal;
  position: relative;
  cursor: pointer;
  &__mobile {
    background: #4caac6;
    color: white;
    font-size: 12px;
    font-family: 'Elliot', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
}

.hidden {
  opacity: 0;
}

.text-small {
  font-size: $font-size-sm;
}

.mobile-h1 {
  font-family: 'Elliot', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
}

.wrapper {
  max-width: 594px;
  margin: auto;
  text-align: center;
  &__mobile {
    max-width: 320px;
    margin: auto;
    text-align: center;
  }
}

.placeholder {
  text-align: center;
  display: block;
  font-size: 20px;
  line-height: 125%;
  color: #c4c4c4;
  text-transform: uppercase;
}

.header-margin {
  margin-top: 7rem;
}

.vertical-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 800px;
}

.container-xlg,
.container-fluid--sitewidth {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: $screen-xlg + 40) {
    max-width: $screen-xlg;
  }
}
.container-xxlg,
.container-fluid--sitewidth {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: $screen-xxlg + 40) {
    max-width: $screen-xlg;
  }
}
.container-xxxlg,
.container-fluid--sitewidth {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: $screen-xxxlg + 40) {
    max-width: $screen-xlg;
  }
}

.disclaimer {
  font-size: 0.75rem;
}

.img-full {
  width: 100%;
  height: auto;
}

.index-1 {
  position: relative;
  z-index: 1;
}
.index-0 {
  position: relative;
  z-index: 0;
}
.bg-pattern {
  @include bg-pattern;
}
.text-blue {
  color: #002855;
}

.icon {
  &-blue {
    color: $primary;
  }
  &-small {
    max-width: 90px;
    height: auto;
  }
}

.text-purple {
  color: $simplyPurple;
}

.form-input {
  border-radius: 0 !important;
  min-height: 56px;
}

.text-dusty {
  color: $simplyDusty;
}

.text-link {
  color: #3c7ca1;
}

.mb-4vw {
  margin-bottom: 4vw;
}

a sup {
  display: inline-block;
  text-decoration: none !important;
}

.button-border {
  border: 1px solid $simplyPurple;
  border-radius: 40px;
}

.fs-mark-reg::after {
  content: '\00AE'; // ®
  font-size: 65%;
  line-height: 1%;
  vertical-align: super;
}

.text-dark-blue {
  color: $darkblue;
}

.text-underline {
  text-decoration: underline;
}

.h-md-100 {
  @media (min-width: $screen-md) {
    height: 100%;
  }
}
.h-lg-100 {
  @media (min-width: $screen-lg) {
    height: 100%;
  }
}

.ven {
  background: rgba($white, 0.95);
  width: 200px;
  height: 200px;
  border-radius: 100%;
  transition: all 0.5s ease-out 0.75s;
  opacity: 0.25;
  box-shadow: 0px 4px 4px rgba($black, 0.25);
  @media (min-width: $screen-sm) {
    width: 250px;
    height: 250px;
  }
  @media (min-width: $screen-md) {
    width: 275px;
    height: 275px;
  }
  @media (min-width: $screen-xl) {
    width: 300px;
    height: 300px;
  }

  &__img {
    max-width: 60%;
    align-self: flex-start;
    @media (min-width: $screen-lg) {
      max-width: 175px;
    }
  }
  &--top {
    transform: translate(-20px, 0) rotate(-3deg) scale(0.9);
    &.active {
      opacity: 1;
      transform: translate(-50px, 35px) rotate(0) scale(1);
      @media (min-width: $screen-lg) {
        transform: translate(-100px, 70px) rotate(0) scale(1);
      }
    }
  }
  &--btm {
    transform: translate(-20px, 0) rotate(3deg) scale(0.9);
    &.active {
      opacity: 1;
      transform: translate(50px, -35px) rotate(0) scale(1);
      @media (min-width: $screen-lg) {
        transform: translate(100px, -70px) rotate(0) scale(1);
      }
    }
  }
}

.text-green {
  color: $simplyGreen;
  overflow-wrap: anywhere;
}

.min-h {
  &-50 {min-height: 50px;}
  &-80 {min-height: 80px;}
}

.w-16 {width: 16rem;} // 256px
.w-21 {width: 21rem;} // 336px
@media (min-width: $screen-md) {.md\:w-21 {width: 21rem;}}

.bg-top {
  background-position: top;
}

.bg-position-80 {background-position: 80%;}

@media (max-width: $screen-md) {
  .sm-down\:bg-cover {
    background-size: cover;
  }
}

.leading-none {line-height: 1;}
.leading-tight {line-height: 1.25;}

.py-30px {
  padding-top: 30px;
  padding-bottom: 30px;
}

.px-30px {
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: $screen-md) {
  .px-md-90px {
    padding-left: 90px;
    padding-right: 90px;
  }
}
