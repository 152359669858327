@mixin masonry {
  column-gap: 1.25rem;
  orphans: 1;
  widows: 1;
  column-count: 2;
}
.mason {
  @include masonry;
}
.mason-md {
  @media (min-width: $screen-md) {
    @include masonry;
  }
}
.mason-lg {
  @media (min-width: $screen-lg) {
    @include masonry;
  }
}
