// Break out into ./Utilities/context
.text-blue {
  color: $simplyDark;
}

.bg-sky-blue {
  background-color: #0076CF;
  border-color: #0076CF;
  border-radius: 24px;
}

.bg-purple {
  background-color: $simplyPurple;
}

.bg-light-purple {
  background-color: $lightPurple;
}

.bg-lite {
  background: rgba(196, 196, 196, 0.1);
  border: 2px solid #0091DA;
  box-sizing: border-box;
  border-radius: 5px;
}

.bg-sky-blue:hover {
  background-color: #0076CF;
  border-color: #0076CF;
  border-radius: 24px;
}

.bg-blue-whale {
  background: $principalDarkBlue;
}

.bg-simply-dark-blue {
  background-color: $simplyDark;
}
.bg-iron {
  background-color: $iron;
}
.bg-concrete {
  background-color: #f2f2f2;
}
