.safeharbor-table {
  &.no-border {
    border: none !important;
  }
  &.border {
    border-bottom: 3px solid #002855 !important;
  }
  &-row {
    width: 25%;
  }
  &-row-border {
    border-bottom: 2px solid #c8ccce !important;
  }
}
