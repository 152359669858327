.custom-uploader {
  &-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  &-input {
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
    line-height: 0;
  }
  &-text {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
    padding: .5rem 1rem;
    padding-right: 100px;
    line-height: 2.5;
    color: #555;
    border: .075rem solid transparent;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::before {
      position: absolute;
      top: -.075rem;
      right: -.075rem;
      bottom: -.075rem;
      z-index: 6;
      display: block;
      content: "Browse";
      padding: .5rem 1rem;
      line-height: 2.5;
      color: #555;
      background-color: #eee;
    }
  }
}

.uploadSection {
  position: relative;
  margin: 25px 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputLabel {
  top: -21px;
  font-size: 13px;
  color: black;
  left: 0;
  position: absolute;
}

.formField {
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
}

.dragDropText {
  font-weight: bold;
  letter-spacing: 2.2px;
  margin-top: 0;
  text-align: center;
}

.uploadFileBtn {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #3498db;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 1.1em 2.8em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 6px;
  color: #3498db;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 250ms ease-in-out;
  font-family: "Open Sans", sans-serif;
  width: 45%;
  display: flex;
  align-items: center;
  padding-right: 0;
  justify-content: center;

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: #3498db;
    z-index: -1;
    transition: width 250ms ease-in-out;
  }

  i {
    font-size: 22px;
    margin-right: 5px;
    border-right: 2px solid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media only screen and (max-width: 500px) {
    width: 70%;
  }

  @media only screen and (max-width: 350px) {
    width: 100%;
  }

  &:hover {
    color: #fff;
    outline: 0;
    background: transparent;

    &:after {
      width: 110%;
    }
  }

  &:focus {
    outline: 0;
    background: transparent;
  }

  &:disabled {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
}

.filePreviewContainer {
  margin-bottom: 35px;
  span {
    font-size: 14px;
  }
}

.previewList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
}

.fileMetaData {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: rgba(5, 5, 5, 0.55);

  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }
}

.removeFileIcon {
  cursor: pointer;

  &:hover {
    transform: scale(1.3);
  }
}

.previewContainer {
  padding: 0.25rem;
  width: 150px;
  height: 150px;
  border-radius: 6px;
  box-sizing: border-box;

  &:hover {
    opacity: 0.55;

    .fileMetaData {
      display: flex;
    }
  }

  & > div:first-of-type {
    height: 100%;
    position: relative;
    display: flex;
  }
}

.imagePreview {
  border-radius: 6px;
  width: 100%;
  margin: auto;
}
