@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    } @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == 'list' {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  } @else {
    font-size: $fs-font-size;
  }
}

$breakpoints: (
  xs: $screen-xs,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl,
  xxl: $screen-xxlg,
  xxxl: $screen-xxxlg,
);



$fs-h1-font-sizes: (
  null: $font-size-base * 1.75, // 32px
  xs: $font-size-base * 1.75,
  sm: $font-size-base * 2,
  md: $font-size-base * 2.5, // 40px
  lg: $font-size-base * 3, // 48px
  xl: $font-size-base * 3.125, // 50px
);

$fs-h2-font-sizes: (
  null: $font-size-base * 1.125, // 18px
  xs: $font-size-base* 1.125,
  lg: $font-size-base * 1.375 // 22px
);

$fs-h3-font-sizes: (
  null: $font-size-base * 1.5, // 24px
  xs: $font-size-base * 1.5,
  sm: $font-size-base * 1.5,
  md: $font-size-base * 1.5,
  lg: $font-size-base * 1.75,
  xl: $font-size-base * 1.75, // 28px
);

$fs-h4-font-sizes: (
  null: $font-size-base * 1.125, // 18px
  xs: $font-size-base * 1.125,
  md: $font-size-base * 1.25,
  lg: $font-size-base * 1.5, // 24px
);

$fs-h5-font-sizes: (
  null: $font-size-base * 1.0625, // 17px
  xs: $font-size-base * 1.0625,
  md: $font-size-base * 1.1875,
  lg: $font-size-base * 1.1875,
  xl: $font-size-base * 1.1875, // 19px
);

$fs-h6-font-sizes: (
  null: $font-size-base, // 16px
  xs: $font-size-base,
  md: $font-size-base * 1.125,
  lg: $font-size-base * 1.125,
  xl: $font-size-base * 1.125, // 18px
);

$fs-display1-font-sizes: (
  null: $font-size-base * 2.25,
  xs: $font-size-base * 2.25,
  sm: $font-size-base * 2.5,
  md: $font-size-base * 2.75,
  lg: $font-size-base * 3,
  xl: $font-size-base * 3.25,
);

$fs-display2-font-sizes: (
  null: $font-size-base * 2.3125, // 37px
  xs: $font-size-base * 2.3125,
  md: $font-size-base * 3,
  lg: $font-size-base * 3,
  xl: $font-size-base * 3, // 48px
);

$fs-display3-font-sizes: (
  null: $font-size-base * 2.3125, // 37px
  xs: $font-size-base * 2.3125,
  md: $font-size-base * 2.125,
  lg: $font-size-base * 2.125,
  xl: $font-size-base * 2.125, // 34px
);

$fs-display5-font-sizes: (
  null: $font-size-base * 1.125, // 18px
  xs: $font-size-base * 1.125,
  md: $font-size-base * 1.125,
  lg: $font-size-base * 1.375,
  xl: $font-size-base * 1.375, // 22px
);

$fs-tablelabel-font-sizes: (
  null: $font-size-base * 1.625, //24px
  xs: $font-size-base * 1.625, // 24px
  sm: $font-size-base * 1.375, // 22px
);

$fs-p-font-sizes: (
  null: $font-size-base * 0.9375, // 15px
  xs: $font-size-base * 0.9375,
  sm: $font-size-base * 0.9375,
  md: $font-size-base,
  lg: $font-size-base,
  xl: $font-size-base, // 16px
);

$fs-sm-font-sizes: (
  null: $font-size-base * 0.875, // 14px
  sm: $font-size-base * 0.9375, // 15px
);

$fs-disclaimer-font-sizes: (
  null: $font-size-base * 0.875, // 14px
);

$fs-form-q-font-sizes: (
  null: $font-size-base * 1.5, // 28px
  xs: $font-size-base * 1.5,
  sm: $font-size-base * 1.75,
  md: $font-size-base * 2.25,
  lg: $font-size-base * 2.375,
  xl: $font-size-base * 2.5, // 44px
);

$fs-form-label-font-sizes: (
  null: $font-size-base *  .875, // 48px
  sm: $font-size-base,
);

$fs-xl-font-sizes: (
  null: $font-size-base * 3, // 48px
  xs: $font-size-base * 2.5,
  sm: $font-size-base * 3,
  md: $font-size-base * 3.25,
  lg: $font-size-base * 3.5, // 56px
);
$fs-xxl-font-sizes: (
  null: $font-size-base * 3.5, // 56px
  xs: $font-size-base * 3,
  sm: $font-size-base * 3.5,
  md: $font-size-base * 4,
  lg: $font-size-base * 4,
);

$fs-xxxl-font-sizes: (
  null: $font-size-base * 5, // 68px
  xs: $font-size-base * 5,
  sm: $font-size-base * 5,
  md: $font-size-base * 5,
  lg: $font-size-base * 5.75,
  xl: $font-size-base * 6.25, // 100px
);

$fs-form-label-large-font-sizes: (
  null: $font-size-base * 1.125, // 20px
  sm: $font-size-base * 1.25,
  lg: $font-size-base * 1.5, // 24px
);

h1,
.h1,
h2,
.h2 {
  font-weight: normal;
  line-height: 120%;
}

h1 {
  font-size: 2rem;
  line-height: 120%;
  color: $body-color;

  @media (min-width: $screen-sm) {
    font-size: $h1-font-size;
    line-height: 120%;
  }
}

h2 {
  font-weight: bold;
  font-size: $h2-font-size;
  line-height: 165%;
}

h3 {
  font-weight: bold;
  font-size: $h3-font-size;
}

small, .small {
  font-weight: inherit;
}

.font-size-small {
  font-size: 1rem;
}

.font-size-large {
  font-size: 1.75rem;
}

.fs-h1 {
  @include font-size($fs-h1-font-sizes); // 50px => 36px
  line-height: 125%;
}

.fs-h2 {
  @include font-size($fs-h2-font-sizes); // 22px => 16px
  line-height: 165%;
}

.fs-h3 {
  @include font-size($fs-h3-font-sizes); // 28px => 24px
  line-height: 125%;
}

.fs-h4 {
  @include font-size($fs-h4-font-sizes); // 24px => 18px
  line-height: 125%;
}
.fs-h5 {
  font-family: 'Elliot', sans-serif;
  font-style: normal;
  @include font-size($fs-h5-font-sizes); // 19px => 17px
  line-height: 125%;
}
.fs-h6 {
  @include font-size($fs-h6-font-sizes); // 18px => 16px
  line-height: 165%;
}


.fs-display1 {
  @include font-size($fs-display1-font-sizes); // 50px => 36px
  line-height: 125%;
}
.fs-display2 {
  @include font-size($fs-display2-font-sizes); // 48px => 37px
  line-height: 56px;
}
.fs-display3 {
  @include font-size($fs-display3-font-sizes); // 34px => 37px
  line-height: 44px;
}
.fs-display4 {
  font-size: 28px;
  line-height: 125%;
}
.fs-display5 {
  @include font-size($fs-display5-font-sizes); // 22px => 18px
  line-height: 165%;

}
.fs-display6 {
  // scale placeholder
}

.fs-tablelabel {
  @include font-size($fs-tablelabel-font-sizes);
}

.fs-lead {
  font-size: 18px;
  line-height: 34px;
}

.fs-p {
  font-style: normal;
  @include font-size($fs-p-font-sizes); // 16px => 15px
  line-height: 165%;
}

.fs-xl {
  font-style: normal;
  @include font-size($fs-xl-font-sizes); // 56px => 58px
  line-height: 125%;
}

.fs-xxl {
  font-style: normal;
  @include font-size($fs-xxl-font-sizes); // 80px => 56px
  line-height: 125%;
}

.fs-xxxl {
  @include font-size($fs-xxxl-font-sizes); // 100px => 80px
  line-height: 100%;
}

.fs-sm {
  @include font-size($fs-sm-font-sizes); // base 14px
}

.fs-tabtitle {
  font-style: normal;
  @include font-size($fs-p-font-sizes); // 16px => 15px
  line-height: 120%;
  .active & {
    font-size: $font-size-base * 1.125; // 16px => 15px
  }
}

.fs-disclaimer {
  @include font-size($fs-disclaimer-font-sizes); // 14px => 12px
  line-height: 165%;
}

.fs-disclaimer-print {
  font-size: $font-size-base;
  @media print {
    @include font-size($fs-disclaimer-font-sizes);
  }
}

.fs-form-q {
  font-style: normal;
  @include font-size($fs-form-q-font-sizes); // 44px => 28px
  line-height: 125%;
}

.fs-form-label {
  font-style: normal;
  font-weight: bold;
  @include font-size($fs-form-label-font-sizes); // 16px => 15px
  line-height: 125%;
  margin-bottom: 0;
  &--large {
    @include font-size($fs-form-label-large-font-sizes); // 24px
  }
}

.lh-100 {
  line-height: 100% !important;
}

.lh-125 {
  line-height: 125%;
}

.lh-165 {
  line-height: 165%;
}

.text-ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
