.calltoaction-row {
  display: flex;
  justify-content: center;
  min-height: 300px; // fix page jumping

  @media (max-width: $screen-sm) {
    flex-wrap: wrap;
  }
}
.calltoaction-col {
  max-width: 400px;
  margin: 7px 15px;
  align-self: center;
}
.calltoaction-wrapper {
  position: relative;
  min-height: 90px;
  margin-bottom: 10px;
  @media (min-width: $screen-md) {
    min-height: 150px;
    margin-top: -80px;
  }
  @media (min-width: $screen-lg) {
    margin-top: -45px;
  }
}

.actioncard {
  cursor: pointer;
  outline: none;
  width: 100%;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 0 25px;
  background-color: #FFFFFF;
  box-shadow: $box-shadow-default;
  border-radius: 8px;
  @media (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    height: 90px;
  }
  @media (min-width: $screen-md) {
    min-height: 200px;
    display: block;
    text-align: center;
    padding: 0 15px;
    @media (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      height: 200px;
    }
  }
  @media (min-width: $screen-lg) {
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 0 25px;
    @media (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      height: 140px;
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  }

  &__media {
    width: 100px;
    display: block;
    @media (min-width: $screen-md) {
      width: auto;
    }
  }
  &__icon {
    width: 100%;
    @media (min-width: $screen-sm) {
      min-width: 70px;
      max-width: 50px;
    }
    @media (min-width: $screen-md) {
      max-width: 80px;
      padding-top: 20px;
    }
    @media (min-width: $screen-lg) {
      max-width: 80px;
      padding-top: 0;
    }
    &.animate {
        path, ellipse, rect, circle, line {
        stroke-linecap: round;
        stroke-dasharray: 500;
        stroke-dashoffset: 500;
        transform: scale(1.125);
        transform-origin: 50%;
        stroke-width: 4;
        animation: drawPath 2s ease-out forwards .2s;
        @media (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
          stroke-dasharray: 0;
          stroke-dashoffset: 0;
        }
      }
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__caption,
  &__excerpt {
    padding: 20px 10px;
    font-weight: bold;
    line-height: 125%;
    width: 100%;
  }
  &__excerpt {
    display: none;
    color: #FFF;
    text-align: center;
    font-weight: normal;
  }
  &__action {
    display: none;
  }

  &.active {
    flex-flow: column;
    justify-content: space-between;
    background: $simplyPurple;
    @media (min-width: $screen-md) {
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
    }
    @media (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      height: 180px;
    }
    .actioncard__media {
      height: 0;
      overflow: hidden;
      position: relative;
    }
    .actioncard__caption {
      display: none;
    }
    .actioncard__excerpt {
      display: block;
    }
    .actioncard__action {
      padding-bottom: 30px;
      display: block;
    }
  }

  @media (hover: hover) and (min-width: $screen-md) {
    &:hover {
      flex-flow: column;
      justify-content: space-between;
      background: $simplyPurple;
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;

      .actioncard__media {
        height: 0;
        overflow: hidden;
        position: relative;
      }
      .actioncard__caption {
        display: none;
      }
      .actioncard__excerpt {
        display: block;
      }
      .actioncard__action {
        padding-bottom: 30px;
        display: block;
      }
    }
  }
}
