/* stylelint-disable */
@mixin bg-pattern {
  position: relative;
  &::after {
    content: '';
    opacity: 0.15;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    background-color: #e6e7e8;
    background-image: url('../Images/background-graypattern.svg');
    background-size: 100%;
    background-image: repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.25) 400px,
        rgba(57, 68, 78, 0.25) 650px,
        transparent 650px 760px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      );
    @media print {
      display: none;
    }
  }
}
