.radio-group {
  display: flex;
  justify-content: space-evenly;
  flex-flow: column;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  @media (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    height: 500px;
    width: 500px;
  }
  &_inline {
    @media (min-width: $screen-sm) {
      flex-flow: row;
    }
  }
}

.radio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row;
  padding: 20px;
  margin-bottom: 10px;
  position: relative;
  background-color: transparent;
  transition: 0.2s all ease-in-out;
  @media (min-width: $screen-md) {
    padding: 20px 30px;
    margin-bottom: 1.6rem;
    @media (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      display: block;
    }
  }
  &.mb-0 {margin-bottom: 0;}
  &:last-child {
    margin-bottom: 0;
  }

  &__label {
    display: inline-block;
    transition: transform 0.3s;
  }

  &__info {
    margin-top: .5rem;
  }

  &__icon {
    display: inline-block;
    margin-right: 20px;
    transition: 0.2s all;
    fill: none;
    width: 30px;
    height: 30px;
    @media (min-width: $screen-md) {
      width: auto;
      height: auto;
      @media (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        width: 60px;
        height: 60px;
      }
    }
    .stroke {
      stroke-width: 2px!important;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0 0 0 2px #979797, inset 0 0 0 4px #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: -1;
    transition: 0.2s all;

    @media (min-width: $screen-md) {
      border-radius: 25px;
      box-shadow: 0 0 0 3px #979797, inset 0 0 0 4px #fff;
    }
  }
  &_basic .radio__background {display: none;}

  &__input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;
    &:hover {
      ~ .radio__background {
        box-shadow: inset 0 0 0 4px $simplyPurple;
        transform: scale(.98);

      }
    }

    &:focus {
      outline: none;
      ~ .radio__background {
        box-shadow: 0 0 0 6px #0091DA, 0 0 5px 5px #0091DA, inset 0 0 0 4px #fff;
      }
    }

    &:required {
      box-shadow: none;
    }
  }

  &_basic {
    justify-content: center;
    padding-bottom: 0;
    .radio__label {font-size: 20px;}
    .radio__input {
      position: relative;
      opacity: 1;
      height: auto;
      width: auto;
      margin-right: 15px;
    }
  }

  &.has-error & {
    &__background {
      box-shadow: 0 0 0 3px $danger, inset 0 0 0 4px #fff;
    }
  }

  &.radio_confirm,
  &.radio_cancel {
    @media (min-width: $screen-sm) {
      display: flex;
      flex-flow: column;
      width: 200px;
      height: 200px;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      margin: auto;
      @media (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        margin: 25px auto;
      }

      &:before,
      &:after {
        content: '';
        display: block;
      }
    }
  }

  &.radio_confirm &,
  &.radio_cancel & {
    &__icon {
      height: 40px;
      width: 40px;
      @media (min-width: $screen-sm) {
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__background {
      border-radius: 100%;

      @media (max-width: 575px) {
        border-radius: 6px;
      }
    }
  }
  &.radio_confirm & {
    &__icon {
      .fill {fill: #0091DA}
      .stroke {stroke: #0091DA}
      .primary-fill {fill: #0091DA;}
    }
  }

  &.radio_cancel & {
    &__icon {
      .fill {fill: #96282d}
      .stroke {stroke: #96282d}
      .primary-fill {fill: #96282d;}
    }
  }

  &.dirty & {
    &__icon {
      // opacity: 0.5;
      .fill {fill: #979797}
      .stroke {stroke: #979797}
    }

    &__label {
      color: #979797;
    }
  }

  &.active & {
    &__background {
      box-shadow: 0 0 0 6px #0091DA, inset 0 0 0 4px #fff;
      background-color: #fff;
    }
    &__icon {
      color: #029d94;
      animation: slideUp 0.5s forwards;

      @media (max-width: 767px) {
        color: #0091DA;
      }
    }
  }

  &.radio_cancel.active {
    .radio__checkmark {
      color: #96282d;
    }

    .radio__background {
      box-shadow: 0 0 0 6px #96282d, inset 0 0 0 4px #fff;
      background-color: #fff;
    }
  }

  &.radio_cancel {
    .radio__input {
      &:focus {
        ~ .radio__background {
          box-shadow: 0 0 0 6px #96282d, 0 0 5px 5px #96282d, inset 0 0 0 4px #fff;
        }
      }
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(-8%);
  }

  65% {
    transform: translateY(4%);
  }

  80% {
    transform: translateY(-4%);
  }

  95% {
    transform: translateY(2%);
  }

  100% {
    transform: translateY(0%);
  }
}
