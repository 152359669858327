.rc-slider {
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 120px );
  &-tooltip {
    display: none;
    &__head {
      position: relative;
      width: 20px;
      height: 20px;
      left: 50%;
      top: 8px;
      background: #002855;
      transform: rotate(-45deg) translateX(-50%);
    }
    &__body {
      position: relative;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 1px;
      padding-right: 10px;
      max-width: 188px;
      border-radius: 10px;
      background: #002855;
    }
    &__description {
      font-family: 'Elliot', sans-serif;
      line-height: 125%;
      font-size: 12px;
      color: #ffffff;
      text-align: center;
      align-items: center;
    }
  }
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .range-slider__tooltip-text {
      color: #002855;
    }
    &.light {
      .range-slider__tooltip-text {
        color: #002855;
      }
    }
    &.dark {
      .range-slider__tooltip-text {
        color: #ffffff;
      }
    }
    &:hover {
      .rc-slider-tooltip {
        display: block;
      }
    }
  }
  &-tooltip-arrow {
    border-style: none;
  }
  &-track {
    height: 12px;
    background-color: $simplyBlue;
  }
  &-handle {
    background-color: $simplyBlue;
    height: 32px;
    width: 32px;
    margin-top: -12px;
    border-color: $simplyBlue; 
  }
  &-rail {
    height: 12px;
  }
  &-range {
    position: absolute;
    bottom: -20px;
    color: #0076CF;
    &__min {
      left: 72px;
    }
    &__max {
      right: 72px;
    }
  }
  &-button {
    padding: 0;
    font-size: 18px;
    line-height: 18px;
    border: none;
    outline: none;
    background-color: $principalDarkMidnight;
    vertical-align: middle;
    background-image: none;
    user-select: none;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    text-align: center;
    &:active {
      transform: scale(.8); 
    }

    &:focus {
      border: none;
      outline: none;
    }
  }
}
