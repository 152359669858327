.aside-text {
  font-size: 2.25rem;
  font-family: 'Elliot-Bold', sans-serif;
  color: white;
  display: inline-flex;
}

.main-bg {
  z-index: 1;
}

.pt-15 {
  @media (max-width: $screen-sm) {
    padding-top: 3rem;
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-md) {
  .svg-margin {
    margin-top: -900px;
  }
  .container-margin {
    margin-top: -100px;
  }
  .pt-15 {
    padding-top: 5rem;
  }
  .pb-15 {
    padding-bottom: 5rem;
  }
}

@media (min-width: $screen-md) and (max-width: $screen-lg) {
  .svg-margin {
    margin-top: -1200px;
  }
  .pb-15 {
    padding-bottom: 15rem;
  }
  .container-margin {
    margin-top: -200px;
  }

  .pt-15 {
    padding-top: 15rem;
  }
}

@media (min-width: $screen-lg) and (max-width: $screen-xl) {
  .svg-margin {
    margin-top: -1150px;
  }

  .pb-15 {
    padding-bottom: 15rem;
  }

  .container-margin {
    margin-top: -200px;
  }

  .pt-15 {
    padding-top: 15rem;
  }
}

@media (min-width: $screen-xl) and (max-width: $screen-xxlg) {
  .svg-margin {
    margin-top: -1200px;
  }

  .pb-15 {
    padding-bottom: 15rem;
  }

  .container-margin {
    margin-top: -200px;
  }

  .pt-15 {
    padding-top: 15rem;
  }
}

@media (min-width: $screen-xxlg) and (max-width: $screen-xxxlg) {
  .svg-margin {
    margin-top: -1100px;
  }
  .pb-15 {
    padding-bottom: 10rem;
  }

  .container-margin {
    margin-top: -200px;
  }

  .pt-15 {
    padding-top: 10rem;
  }
}

// @media (min-width: 1500px) {
// }

// @media screen and (min-width: 1100px) and (max-width: 1500px) {
//   .svg-margin {
//     margin-top: -1100px;
//   }

//   .container-margin {
//     margin-top: -200px;
//   }
// }

// @media (max-width: 1100px) and (min-width: 800px) {
//   .svg-margin {
//     margin-top: -1100px;
//   }

//   .container-margin {
//     margin-top: -200px;
//   }
// }

.oval {
  z-index: 1;
  min-height: 100px;
  margin-top: -300px;
  background: #ffffff;
  border-radius: 50%;
}

.rectangle {
  height: 50px;
  background: #fff;
  margin-top: -100px;
}

.ow {
  margin-top: -400px;
}
/* Ellipse 2.1 */
// .card-24 {
//   width: 170px; /* ⬅ Size - Small (width: 200px), Medium (width: 225px), Large (width: 250px) */
//   overflow: hidden;
//   margin-top: -50px;
//   max-height: 130px;
//   background-color: #ffffff;
//   margin-left: 50px;
//   margin-right: 50px;
//   border-radius: 15px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
//   text-align: center;
//   -webkit-transition: 0.1s ease-in-out;
//   transition: 0.1s ease-in-out;
// }

// .card-24:hover {
//   margin-top: -10px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
// }

// .banner {
//   height: 100px;
//   width: 100%;
//   padding-top: 30px;
//   background-color: #fafafa;
//   background-size: cover;
//   background-position: center;
// }

.white-pill {
  background: #002855;
  border-radius: 50px;
  padding: 1px;
  margin-bottom: 10px;
  min-width: 400px;
  @media (max-width: $screen-xs) {
    min-width: 280px;
  }
  @media (max-width: $screen-sm) {
    min-width: 360px;
  }
}

.white-pill:hover {
  cursor: pointer;
}

.white-avatar {
  background-color: #ffffff;
  width: 60px;
  margin-top: 4px;
  margin-left: 8px;
  height: 50px;
  border-radius: 50%;
  @media (max-width: $screen-xs) {
    margin: 4px;
  }
}

.hvr-bounce-in {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover,
.hvr-bounce-in:focus,
.hvr-bounce-in:active {
  transform: scale(1.2);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

.hvr-sweep-to-top {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-sweep-to-top:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0076CF;
  transform: scaleY(0);
  transform-origin: 50% 100%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-top:hover,
.hvr-sweep-to-top:focus,
.hvr-sweep-to-top:active {
  color: white;
}
.hvr-sweep-to-top:hover:before,
.hvr-sweep-to-top:focus:before,
.hvr-sweep-to-top:active:before {
  transform: scaleY(1);
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}

.tab-position {
  position: relative;
}

.tab-position span {
  position: absolute;
  bottom: 0;
}

.center-align {
  vertical-align: middle;
  margin-top: 18px;
  font-size: 1.3rem;
  width: 100%;
  text-align: left;
  margin-left: 20px;
  @media (max-width: $screen-xs) {
    font-size: 1rem;
  }
}

.main-text-small {
  font-size: 1.25rem;
  font-family: 'Elliot-Light', sans-serif;
  color: #fcfcfd;
  line-height: 1.5;
}

.react-tabs {
  z-index: 3;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab-list {
  margin-top: -70px;
}

.react-tabs__tab {
  width: 16.6%;
  min-height: 64px;
  text-align: center;
  color: white;
  padding: 25px;
}

.react-tabs__tab:nth-child(1) {
  background: #0076CF;
}

.react-tabs__tab:nth-child(2) {
  background: #0076CF;
}

.react-tabs__tab:nth-child(3) {
  background-color: #0076CF;
}

.react-tabs__tab:nth-child(4) {
  background-color: #0076CF;
}

.react-tabs__tab:nth-child(5) {
  background-color: #0076CF;
}

.react-tabs__tab:nth-child(6) {
  background-color: #0076CF;
}

.bucket__group {
  max-width: 480px;
  margin: 0 auto;
}
.bucket {
  display: flex;
  background: white;
  margin-bottom: 15px;
  border-radius: 45px;
  cursor: pointer;
  padding: 15px;
  transition-property: transform, padding, margin, min-height;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 0;
  align-items: center;
  justify-content: center;
}

.bucket__inner {
  position: relative;
  z-index: 2;
  display: flex;
  width: calc(100% - 120px);
}
.bucket__icon {
  border-radius: 100%;
  display: flex;
  width: 100px;
  height: 100px;
  margin-right: 10px;
  background: transparent;
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  transform-origin: bottom center;
}
.bucket__head {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.bucket__content {
  display: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding: 0;
  margin-top: 40px;
}
.bucket.active .bucket__inner {
  width: 100%;
}
.bucket.active .bucket__head {
  display: none;
}
.bucket.active .bucket__content {
  display: flex;
}
.bucket.active {
  border-radius: 50px;
  transform: scale(1.15);
  margin-top: 100px;
  margin-bottom: 30px;
  z-index: 1;
  background: linear-gradient(180deg, #0076CF 0%, #0076CF 120.05%);
}
.bucket.active .bucket__icon {
  width: 125px;
  height: 125px;
  position: absolute;
  top: -62.5px;
  left: calc(50% - 62.5px);
  z-index: 3;
}
.bucket.inactive .bucket__head {
  font-size: 18px;
  line-height: 24px;
}
.bucket.inactive .bucket__icon {
  width: 120px;
  height: 100px;
}
.bucket.inactive .bucket__inner {
  width: calc(100% - 60px);
}

.bucket:hover {
  transform: scale(0.95);
}

.bucket.active:hover {
  transform: scale(1.15);
}

.bucket.inactive:hover {
  transform: scale(0.95) translateY(0);
  background: lightgray;
}
.bucket.inactive:hover .bucket__icon {
  background: white;
}

.svg-icon {
  top: 16px;
  left: 20px;
  position: relative;
}

.secondary-text-small {
  font-size: 1.5rem;
  font-family: 'Elliot-Light', sans-serif;
  line-height: 1.5;
  @media (max-width: $screen-sm) {
    font-size: 1.3rem;
  }
}
