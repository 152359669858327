.slide-pane {
  display: flex;
  flex-direction: column;
  background: #002855;
  min-width: 300px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s;
  will-change: transform;
  z-index: 5000;
}
.slide-pane:focus {
  outline-style: none;
}
.slide-pane_from_right {
  margin-left: auto;
  transform: translateX(100%);
  z-index: 5000;
}
.slide-pane_from_right.ReactModal__Content--after-open {
  transform: translateX(0%);
}
.slide-pane_from_right.ReactModal__Content--before-close {
  transform: translateX(100%);
}
.slide-pane_from_left {
  margin-right: auto;
  transform: translateX(-100%);
}
.slide-pane_from_left.ReactModal__Content--after-open {
  transform: translateX(0%);
}
.slide-pane_from_left.ReactModal__Content--before-close {
  transform: translateX(-100%);
}
.slide-pane_from_bottom {
  height: 90vh;
  margin-top: 10vh;
  transform: translateY(100%);
}
.slide-pane_from_bottom.ReactModal__Content--after-open {
  transform: translateY(0%);
}
.slide-pane_from_bottom.ReactModal__Content--before-close {
  transform: translateY(100%);
}
.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.slide-pane__overlay.ReactModal__Overlay--after-open {
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s;
}
.slide-pane__overlay.ReactModal__Overlay--before-close {
  background-color: rgba(0, 0, 0, 0);
}
.slide-pane__header {
  display: flex;
  flex: 0 0 64px;
  align-items: center;
  background: white;
  height: 64px;
}
.slide-pane__title-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 160px;
  margin-top: 20px;
  min-width: 0;
}
.slide-pane__title {
  font-family: 'Elliot', sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 22px;
  color: #002855;
  line-height: 20px;
  margin-left: 0.5rem;
}
.slide-pane__content {
  position: relative;
  overflow-y: auto;
  padding: 24px 0px;
  flex: 1 1 auto;
  &__title {
    font-family: 'Elliot', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 0.5rem;
    &.detail {
      font-size: 14px;
      color: white;
      text-transform: initial;
    }
  }
  &__note {
    font-family: 'Elliot', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    text-transform: uppercase;
    &.detail {
      font-size: 13px;
      color: white;
      text-transform: initial;
    }
  }
  &__text {
    font-family: 'Elliot', sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 28px;
    color: #002855;
    margin: 0.5rem !important;
    &.detail {
      font-size: 18px;
      color: white;
    }
  }
  &__sup {
    font-family: 'Elliot', sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 14px;
    color: #002855;
    &.detail {
      color: white;
      font-size: 11px;
    }
  }
  &__sub {
    font-family: 'Elliot', sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 13px;
    color: #002855;
    &.detail {
      color: white;
      font-size: 10px;
    }
  }
  &__icon {
    position: absolute;
    right: 30px;
  }
}

sub {
  bottom: 0px !important;
}
.slide-pane__subtitle {
  font-size: 12px;
  margin-top: 2px;
}

.slide-pane__close {
  position: absolute;
  right: 15px;
  top: 20px;
  width: 32px;
  height: 32px;
  text-indent: -999rem;
  background-color: transparent;
  border: 0;
  &:hover {
    opacity: 1;
  }
  &::before,
  &::after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 15px;
    z-index: 1;
    width: 2px;
    background-color: #979797;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
