@font-face {
  font-family: 'Elliot';
  src: url('../assets/fonts/fselliotpro-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/fselliotpro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Elliot';
  src: url('../assets/fonts/fselliotpro-bold-webfont.woff2') format('woff2'),
    url('../assets/fonts/fselliotpro-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Elliot';
  src: url('../assets/fonts/fselliotpro-italic-webfont.woff2') format('woff2'),
    url('../assets/fonts/fselliotpro-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
