.section {
  &__label {
    font-size: 20px;
    color: #002855;
    font-family: 'Elliot', sans-serif;
    font-style: normal;
    font-weight: normal;
    align-self: center;
    text-transform: uppercase;
    &__mobile {
      font-size: 16px;
      color: #002855;
      font-family: 'Elliot', sans-serif;
      font-style: normal;
      font-weight: normal;
      align-self: center;
      text-transform: uppercase;
    }
  }

  &__value {
    font-size: 32px;
    color: #002855;
    align-self: center;
    font-family: 'Elliot', sans-serif;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    &__mobile {
      font-size: 22px;
      color: #002855;
      align-self: center;
      font-family: 'Elliot', sans-serif;
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__icon-grey {
    font-size: 12px;
    color: #bababa;
    cursor: pointer;
    padding-left: 10px;
    vertical-align: middle;
    margin-bottom: 5px;
  }

  &__rounded {
    border-radius: 10px;
  }
}
