 .contactlist {
  opacity: 0;
  &__item {
    list-style: none;
  }
  &__link {
    color: $white;
    font-size: $font-size-base * 1.125;
    font-weight: bold;
    text-decoration: none;

  }
 }
 .userlist {
  margin: 0;
  opacity: 0;
  transform: translateY(60px);
  transition: all .5s cubic-bezier(0.7, 0, 0.3, 1);
  width: 100%;
  padding: 0 60px 0 15px;
  background: $principalDarkBlue;
  @media (min-width: $screen-lg) {
    opacity: 1;
    transform: none;
    padding: 0;
    background-color: transparent;
    display: block;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
  }

  &__item {
    list-style: none;
    text-align: left;
    display: inline-block;
    padding-right: 20px;
    width: min-content;
    @media (min-width: $screen-md) {
      width: auto;
      padding: 15px;
    }
    @media (min-width: $screen-lg) {
      padding: 0;
      text-align: center;
      font-weight: bold;
      max-width: 100%;
      min-width: 0;
      width: auto;
    }
    @media (min-width: $screen-lg) {
      &.divider {
        &::after {
          content: '|';
          display: inline-block;
          font-weight: bold;
          font-size: $font-size-base * .875;
          color: $simplyDusty;
        }
      }
    }
  }

  &__link {
    position: relative;
    margin: 0;
    color: #fff;
    line-height: 125%;
    display: inline-block;
    transition: ease-out 0.15s;
    box-shadow: inset 0 0 0 0 rgba($simplyPurple, .0);
    text-decoration: none;
      font-size: $font-size-base * .875;
    padding: 15px 0 10px;

    @media (min-width: $screen-lg) {
      font-size: $font-size-base * .875;
      padding: 1rem 0;
      margin: 0 1rem;
      color: $charcoal;
    }

    &:hover {
      color: $simplyPurple;
      text-decoration: none;
      box-shadow: inset 0 5px 0 0 rgba($simplyPurple, 1);
    }

    &::after {
      @media (min-width: $screen-lg) {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        transition: all 0.2s;
        box-shadow: inset 0 0 0 $simplyPurple;
      }
    }

    &.active {
      border-bottom: 2px solid $white;
      @media (min-width: $screen-lg) {
        border-bottom: 0;
        color: $simplyPurple;
        &:hover {
          box-shadow: none;
        }
        &::after {
          box-shadow: inset 0 3px 0 $simplyPurple;
        }
      }
    }
    &.no-link {
      &:hover {
        color: $simplyPurple;
        text-decoration: none;
        box-shadow: none;
        cursor: text;
      }
    }
  }
}

.navlist {
  margin: 0;
  opacity: 0;
  transform: translateY(60px);
  transition: all .6s cubic-bezier(0.7, 0, 0.3, 1);
  padding: 30px 0 30px;
  position: relative;
  text-align: center;

  @media (min-width: $screen-lg) {
    text-align: right;
    opacity: 1;
    transform: none;
    padding-top: 0;
    padding-bottom: 20px;
    background-color: transparent;
    display: block;
    overflow: visible;
  }

  &__item {
    list-style: none;
    display: block;
    transition: transform 0.8s cubic-bezier(0.7, 0, 0.3, 1);

    @media (min-width: $screen-lg) {
      display: inline-block;
      padding: 0;
    }
    .dropdown-menu {
      float: none;
      border-radius: 0;
      @media (min-width: $screen-lg) {
        float: left;
        border-radius: 30px;
      }
    }

    .nav-link {
      display: block;
      font-weight: bold;
      color: $white;
      font-size: $font-size-base * 1.125;
      transition: .2s ease-out;
      text-decoration: none;
      padding: 10px 15px;
      border-bottom: 2px solid $principalDarkMidnight;
      text-align: left;
      outline: 0;
      &::after {
        position: absolute;
        right: 20px;
        top: 27px;
        background: url('../Images/arrow-down-light.svg');
        border: none;
        width: 11px;
        height: 7px;
        @media (min-width: $screen-sm) {
          position: static;
        }
        @media (min-width: $screen-lg) {
          position: relative;
          right: -2px;
          top: 2px;
          background: url('../Images/arrow-down-dark.svg');
        }
      }
      @media (min-width: $screen-lg) {
        border: none;
        color: $charcoal;
        font-size: $font-size-base;
        padding: $button-padding-y 0.5rem;
      }

      @media (min-width: $screen-xl) {
        font-size: $font-size-base * 1.125;
        padding: $button-padding-y 1rem;
      }

      &:focus,
      &.active {
        color: $primary;
        text-decoration: none;

        &:hover {
          text-shadow: none;
          transform: none;
        }
      }

      &.show {
        .dropdown-toggle {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 3px;
            display: block;
            background: $simplyPurple;
          }
        }
      }
    }
  }

.navlist__item.show .nav-link {
  background: $principalDarkBlue;
  @media (min-width: $screen-lg) {
    background-color: transparent;
  }
}

  &__btn {
    margin-top: 20px;
    font-size: $font-size-base * 1.25;

    @media (min-width: $screen-lg) {
      margin-top: 0;
      font-size: $font-size-base * 0.8;
      padding: 0.5rem 1.2rem;
    }

    @media (min-width: $screen-lg) {
      // font-size: 0.8rem;
      // padding: 0.5rem 1.2rem;
    }

    @media (min-width: $screen-lg) {
      font-size: $font-size-base;
      padding: 15px 40px;
    }
  }
}

.navmenu {
  position: absolute;
  width: 100%;
  z-index: 99999;

  &__navbar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (min-width: $screen-lg) {
      align-items: center;
    }
  }
  &__header {
    padding-top: 25px;
    padding-bottom: 25px;
    @media (min-width: $screen-lg) {
      padding: 0;
    }
  }

  &__brand {
    width: 230px;
    display: block;
    transition: all .2s ease-in;

    @media (min-width: $screen-lg) {
      width: 330px;
    }
  }

  &__menu-container {
    display: none;
    position: absolute;
    opacity: 0;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
    transform: translate3d(100vw, 0, 0);
    transition: opacity 0.1s, border-radius 0.4s cubic-bezier(0.7, 0, 0.3, 1), transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
    @media (min-width: $screen-lg) {
      display: block;
      position: relative;
      border-radius: 0;
      opacity: 1;
      transform: none;
    }

    &.show-overlay {
      @media (max-width: ($screen-lg - 1)) { // never use MAX -- This one if free
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        display: flex;
        flex-flow: column;
        height: 100vh;
        overflow: hidden;
        background-color: #002855;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        @media (min-width: $screen-lg) {
          overflow: auto;
        }

        .userlist,
        .navlist,
        .contactlist {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
  @media (min-width: $screen-lg) {
    &.affixed-top {
      position: fixed;
      top: 0;
      background-color: rgba(255, 255, 255, 0.85);
      .navmenu__brand {
        width: 230px;
      }
      .nav-link {
        font-size: $font-size-base;
        padding: $button-padding-y 0.875rem;
      }
      .navlist__btn {
        font-size: 0.8rem;
        padding: 0.5rem 1.2rem;
      }
      .userlist__link {
        font-size: 0.75rem;
        padding: 0.5rem 0;
        margin: 0 0.5rem;
      }
    }
  }
}

