// ------------------------------------------------
// Import components
// ------------------------------------------------
@import './variables';
@import './mixins';
@import './FontFamily.scss';
@import './typography';
@import 'animate.css/animate';
@import 'animations';
@import 'hover.css';
@import './components/section';
@import './components/dropdown';
@import './components/HeaderSection';
@import './components/ActionCard';
@import './components/SimplyFeatures';
@import './components/Secondary.style';
@import './components/Bottom.style';
@import './components/Tabs.style';
@import './components/WhySimplyRetirement';
@import './components/PrincipalCard.style.scss';
@import './components/PrincipalTabs.style';
@import './components/PrincipalButton.style';
@import './components/ResourceCard.style.scss';
@import './components/NavMenu';
@import './components/BurgerBtn';
@import './components/Close.scss';
@import './components/CostEstimator.scss';
@import './components/FirstSection.scss';
@import './components/Form.scss';
@import './components/FormSection.scss';
@import './components/GoBack.scss';
@import './components/Label.scss';
@import './components/LetsBeginSection.scss';
@import './components/BeginSection.scss';
@import './components/ProgressTracker.scss';
@import './components/Radio.scss';
@import './components/RangeSlider.scss';
@import './components/SecondSection.scss';
@import './components/SectionResults.scss';
@import './components/SidePanel.scss';
@import './components/SummaryCard.scss';
@import './components/ReviewSelctions.scss';
@import './components/FormWithSteps.scss';
@import './components/PlanOutLine.scss';
@import './components/QuickQuote.scss';
@import './components/Icon.scss';
@import './components/UserInfo.scss';
@import './components/StepWizard.scss';
@import './components/SafeHarbor.style.scss';
@import './components/Toast.scss';
@import './components/ApplicationInfoPanel.scss';
@import './components/RoiCalculator.scss';
@import './components/TableFluid';
@import './components/TableFluidLarge';
@import './components/Equation';
@import './components/FileUpLoader.scss';
@import './components/PlanProposal';

@import './typography.scss';
@import './print.scss';
@import './utilities'; // Must be the last import!

:root {
  --bs-modal-bg: #fff;
  --bs-modal-border-radius: 20px;
  --bs-modal-border-width: 1px;
  --bs-modal-border-color: #fff;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0px;
}

// ------------------------------------------------
// Reset browser defaults
// ------------------------------------------------
html {
  touch-action: manipulation;
}

html,
body {
  font-family: $baseFont, sans-serif;
  font-size: 16px; // needed to set base font size
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
.root {
  max-width: 100%;
  overflow-x: hidden;
}

.root {
  overflow: hidden;
}

.btn-round {
  background: $principalPrimaryAlt;
  border-radius: 50px;
  padding: 10px;
  line-height: 1.8;
}

.overlay-9 {
  z-index: 3;
}

.circle {
  position: relative;
  display: block;
  margin: 2em 0;
  min-width: 45rem;
  z-index: 2;
  background-color: transparent;
  color: #f2f2f2;
  top: -300px;
  text-align: center;

  @media (max-width: $screen-sm) {
    z-index: -1;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-md) {
    min-width: 58rem;
    top: -265px;
  }

  @media (min-width: $screen-md) and (max-width: $screen-lg) {
    min-width: 56rem;
    top: -355px;
    right: 67px;
  }

  @media (min-width: $screen-xl) and (max-width: $screen-xxlg) {
    min-width: 60rem;
    top: -435px;
  }
}

.circle:after {
  display: block;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #f2f2f2;
  content: '';
}

.circle__inner {
  position: absolute;
  top: 200px;
  bottom: 0;
  left: -50px;
  width: 100%;
  height: 75%;
}

.circle__wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

.circle__content {
  display: table-cell;
  padding: 1em;
  vertical-align: middle;

  @media (min-width: $screen-xl) and (max-width: $screen-xxlg) {
    padding: 16em 20em 16em 16em;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-md) {
    padding: 7em 18em 1em 1em;
  }

  @media (min-width: $screen-md) and (max-width: $screen-lg) {
    padding: 5em 10em 1em 1em;
  }
}

.aside-text-small {
  font-size: 1.5rem;
  font-family: 'Elliot-Bold', sans-serif;
  color: #fcfcfd;
  line-height: 1.5;

  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

.no-border-button {
  border: none;
  outline: none;
  background: transparent;
  color: $charcoal;
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
}

// Loading animation
.lda-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.no-scroll {
  overflow: hidden !important;
}

.index-3 {
  z-index: 3;
}

.__react_component_tooltip {
  pointer-events: auto !important;
}

.sticky-wrapper {
  position: relative;
  height: 3rem;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}
.sticky .sticky-inner {
  @media screen {
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    margin: 20px 0;
  }
}

a[href^='tel:'] {
  @media (min-width: $screen-md) {
    color: $copy;
    text-decoration: none;
    cursor: text;
  }
}

.custom-checkbox .form-check-input:checked ~ .form-check-label::before {
  background-color: $primary !important;
}

.advisors-carousel {
  @media screen {
    &--hide {display: none !important;}
  }
}

.next-container {
  background-color: #fff;
  margin-top: 0;
  position: relative;
  @media (min-width: 768px) {
    margin-top: 140px;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    transform: scale(1.2) translateY(-50%);
  }
}
