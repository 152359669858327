.cost-estimator {
  &-border {
    border: 1px solid #979797;
  }
  &-info-border {
    border-top: 0.5px solid #969696;
  }
  &-info {
    border: none;
  }
  &-infotip {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 50px;
    width: 50px;
    height: 50px;
    background-color: #eeeeee;
    transform: rotate(-45deg);
  }
}
