.principal-tabs {
  position: relative;
  &__wrapper {
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    @media (min-width: $screen-md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__tablist {
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
    width: 100%;
    overflow-x: scroll;
    background-color: $lightgray;
    @media (min-width: $screen-md) {
      border-radius: 25px 25px 0px 0px;
    }
    @media print {
      display: none;
    }
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &__panels {
    width: 100%;
    position: relative;
    border-radius: 0px 0px 25px 25px;
    overflow: hidden;
    &.shadowed {
      background-color: #ffffff;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
    }
    @media print {
      overflow: visible;
    }
  }
  &__panel {
    position: absolute;
    width: 100%;
    &.show {
      position: relative;
    }
    @media print {
      transform: none;
      opacity: 1;
      position: relative;
      display: block;
      animation-fill-mode: none;
    }
  }
}

.tab-controls {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0;
  width: 30px;
  background-color: #002855;
  color: $white;
  z-index: 2;
  border: none;
  margin: 0;
  padding: 0;
  overflow: visible;

  &.left {
    left: 0;
    right: auto;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    @media (min-width: $screen-md) {
      border-top-left-radius: 25px;
    }
  }
  &.right {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    @media (min-width: $screen-md) {
      border-top-right-radius: 25px;
    }
  }
}
.principal-nav {
  display: flex;
  flex-flow: nowrap;
  margin: 0 auto;
  position: relative;
  padding-left: 0px;
  @media (min-width: $screen-md) {
    border-radius: 25px 25px 0px 0px;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 25px 10px;
    border-right: 5px solid $white;
    font-weight: bold;
    line-height: 125%;
    text-decoration: none;
    color: $charcoal;
    @media (min-width: $screen-md) {
      padding: 25px 30px;
    }

    &::after {
      content: '';
      z-index: 1;
      height: 5px;
      border-radius: 5px;
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0;
    }
  }
  &__item {
    list-style: none;
    display: block;
    transition: all 0.15s ease-in-out;
    flex: 0 0 50%;
    max-width: 50%;
    overflow: hidden;
    @media (min-width: $screen-sm) {
      flex: 0 0 33%;
      max-width: 33%;
    }
    @media (min-width: $screen-md) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @media (min-width: $screen-lg) {
      flex: 1 1 auto;
      max-width: inherit;
    }
    &:hover {
      .principal-nav__link {
        color: $simplyPurple;
        background-color: $white;
        text-decoration: none;
        &::after {
          background-color: $simplyPurple;
          border-radius: 0;
        }
      }
    }
    &.active {
      .principal-nav__link {
        background-color: $white;
        color: $simplyPurple;
        text-decoration: none;
        &::after {
          background-color: $simplyPurple;
          border-radius: 0;
        }
      }
    }
    &:last-child {
      .principal-nav__link {
        margin-right: 0;
        // border-right: none;
      }
    }
  }
}
