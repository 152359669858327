// Table
.table-fluid-large {
  &__thead {
    display: none;
    @media (min-width: $screen-md) {
      .table-fluid-large__col,
      .table-fluid-large__label {
        border-bottom: 3px solid #002855;
        align-items: flex-end;
        padding: 20px 5px;
        overflow: auto;
      }
      .highlight {
        color: #002855;
        background-color: #edeeef;
      }
    }
  }
  &__row,
  &__thead {
    @media (min-width: $screen-md) {
      display: flex;
      justify-content: space-between;
    }
  }
  &__col,
  &__label {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c8ccce;
    overflow: auto;
    padding: 0;
    @media (min-width: $screen-md) {
      align-items: center;
      padding: 30px 0;
      width: 100%;
      text-align: center;
    }
    .heading,
    .scope {
      font-size: 18px;
      line-height: 115%;
      @media (min-width: $screen-md) {
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }
  &__col {
    @media (min-width: $screen-md) {
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
    }
    &.highlight {
      background-color: #edeeef;
      .scope {
        color: #002855;
      }
    }
  }
  &__label {
    font-weight: bold;
    border-right: 1px solid #002855;
    border-bottom: 3px solid #002855;
    border-top: 1px solid #c8ccce;
    @media (min-width: $screen-md) {
      flex: 0 0 22%;
      max-width: 22%;
      padding: 0 10px;
      border: none;
      border-bottom: 1px solid #c8ccce;
      text-align: left;
      font-weight: normal;
    }
    .label {
      flex: auto;
      padding: 15px;
      margin-left: 35%;
      border-left: 1px solid #c8ccce;
      font-size: 18px;
      display: block;
      text-align: center;
      @media (min-width: $screen-md) {
        font-size: 16px;
        text-align: left;
        flex: none;
        width: 100%;
        margin: 0;
        padding: 0;
        border: none;
      }
    }
    &.mleft-0 .label {
      margin-left: 45%;
      @media (min-width: $screen-md) {
        margin-left: 0;
      }
    }
    &.highlight {
      background-color: #edeeef;
      .scope {
        color: #002855;
      }
    }
  }
  &__row {
    display: none;
    @media (min-width: $screen-md) {
      display: flex;
      &:last-child {
        border-bottom: 1px solid #eceef4;
      }
    }
    &:last-child {
      @media (min-width: $screen-md) {
        .table-fluid-large__col,
        .table-fluid-large__label {
          border-bottom-color: transparent;
        }
      }
    }
    &:hover {
      background-color: #f4f4f4;
    }
    &.active {
      display: block;
      @media (min-width: $screen-md) {
        display: flex;
      }
    }
  }
  .table-col {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    border-right: 1px solid #c8ccce;
    text-align: center;
    @media (min-width: $screen-md) {
      display: block;
      width: 100%;
      border-right: none;
      padding-left: 5px;
      padding-right: 5px;
    }
    &.value {
      margin-right: 10%;
      @media (min-width: $screen-md) {
        margin: 0;
      }
    }
    &.scope {
      align-items: flex-start;
      text-align: left;
      @media (min-width: $screen-md) {
        display: none;
      }
    }
  }
  .table-fluid-large__next-btn {
    background-color: #002855;
    border: none;
    padding: 0;
    margin: 0;
    flex: 0 0 10%;
    color: #fff;
    @media (min-width: $screen-md) {
      display: none;
    }
  }
}
