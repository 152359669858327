.tab-list {
  padding-left: 0;
}

.tab-list-item {
  display: inline-flex;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  border-width: 1px;
  border: solid #ccc;
  width: 25%;
  min-height: 80px;
  color: white;
  font-size: 12px;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 2px;
}

.tab-position {
  position: relative;
  bottom: 0;
}

.tab-list li:nth-child(1) {
  background: #4b77a6;
}

.tab-list li:nth-child(2) {
  background: #4c82ad;
}

.tab-list li:nth-child(3) {
  background-color: #4b8bb3;
}

.tab-list li:nth-child(4) {
  background-color: #4c96b9;
}

.tab-list:nth-child(5) {
  background-color: #4b9fbf;
}

.tab-list:nth-child(6) {
  background-color: #4caac6;
}

@media (min-width: $screen-sm) and (max-width: $screen-md) {
  .apex {
    width: 100%;
    float: left;
    height: 400px;
  }
  .tab-list-item {
    display: inline-flex;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    border-width: 1px;
    border: solid #ccc;
    width: 25%;
    min-height: 80px;
    color: white;
    font-size: 9px;
  }
}

.icon-size {
  font-size: 2rem !important;
}

.tab-text {
  line-height: 1.4;
}
