$startColor: #0076cf;
$endColor: #0076cf;
$ids: 1, 2, 3, 4;

.col-header {
  height: 193px;
  max-width: 462px;
  border-radius: 25px 25px 0px 0px;
  &__mobile {
    max-width: 350px;
    height: 142px;
    border-radius: 25px 25px 0px 0px;
  }
}

.bg-violet-header {
  background: #002855;
}

.bg-blue-header {
  background: #002855;
}

.header__text {
  font-family: 'Elliot', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  &__mobile {
    font-family: 'Elliot', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
  }
  &__container {
    padding: 60px 60px 0px 30px;
  }
  &__container__mobile {
    padding: 30px 50px 0px 10px;
  }
}

@each $id in $ids {
  .row-header-#{$id} {
    @if $id % 2!=0 {
      background: linear-gradient(
        90deg,
        rgba(238, 238, 238, 0.25) 31.88%,
        #eeeeee 100%
      );
    }
    width: 123%;
    min-height: 175px;
    padding: 20px;
    border-right: 10px
      solid
      mix($startColor, $endColor, (calc(100 * $id / 4) * 1%));
    &__text {
      font-family: 'Elliot', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 100%;
      color: #002855;
      &__mobile {
        font-family: 'Elliot', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 100%;
        color: #002855;
      }
      &__tablet {
        font-family: 'Elliot', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 100%;
        color: #002855;
      }
    }
    &__bg-purple {
      display: flex;
      padding: 0.25rem;
      &__text {
        font-family: 'Elliot', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 15px;
        line-height: 135%;
      }
    }
    &__icon {
      color: #4caac6;
      &-description {
        color: #4d4e52;
      }
    }
  }
  .row-description-#{$id} {
    &__container {
      @if $id % 2!=0 {
        background: linear-gradient(
          90deg,
          rgba(238, 238, 238, 0.25) 31.88%,
          #eeeeee 100%
        );
      }
      min-height: 166px;
      padding: 30px 60px 0px 30px;
      &__mobile {
        @if $id % 2!=0 {
          background: linear-gradient(
            90deg,
            rgba(238, 238, 238, 0.25) 31.88%,
            #eeeeee 100%
          );
        }
        min-height: 150px;
        padding: 30px 60px 0px 30px;
      }
    }
    &__text {
      font-size: 48px;
      color: #002855;
      font-family: 'Elliot', sans-serif;
      font-weight: bold;
      @media (max-width: $screen-md) {
        &__sub::before {
          content: '\A';
          white-space: pre;
        }
      }
      &__sub {
        color: #002855;
        font-family: 'Elliot', sans-serif;
        font-weight: bold;
        font-size: 19px;
        @if $id==1 {
          &::before {
            content: '\A';
            white-space: pre;
          }
        }
        @media (max-width: $screen-md) {
          &__tablet::before {
            content: '\A';
            white-space: pre;
          }
        }
        &__info {
          &::before {
            content: '\A';
            white-space: pre;
          }
        }
        &__tablet {
          color: #002855;
          font-family: 'Elliot', sans-serif;
          font-weight: bold;
          font-size: 19px;
        }
        @media (max-width: $screen-md) {
          &__mobile::before {
            content: '\A';
            white-space: pre;
          }
        }
        &__mobile {
          color: #002855;
          font-family: 'Elliot', sans-serif;
          font-weight: bold;
          font-size: 12px;
        }
      }
      &__tablet {
        color: #002855;
        font-family: 'Elliot', sans-serif;
        font-weight: bold;
        font-size: 36px;
      }
      &__mobile {
        color: #002855;
        font-family: 'Elliot', sans-serif;
        font-weight: bold;
        font-size: 28px;
      }
    }
  }
}
