.sidebar__toggle {
  background-color: transparent;
  border: none;
  color: #fff;

  @media (min-width: $screen-md) {
    display: none;
  }
}
.review-selection {
  background-color: #cdccd7;
  border: none;
  border-radius: 10px;
  padding: 15px 30px 15px 15px;
  text-align: left;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  display: block;
  transition: all .2s ease-in;
  width: 100%;
  word-wrap: break-word;

  &::after {
    content: '';
    width: 15px;
    height: 19px;
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-family: 'Font Awesome 6 Free', sans-serif;
    color: #bababa;
    background: url('../Images/edit.svg') no-repeat;

  }

  &__label {
    font-size: 13px;
    color: #4d4e52;
    display: block;
  }

  &__value {
    font-size: 28px;
    color: #002855;
    display: block;
    font-weight: bold;
    line-height: 1;
  }

  &:hover {
    border: 3px solid #002855;
    transform: translateX(-8px);

    &::after {
      color: #4d4e52;
      font-weight: 900;
    }
  }

  &.active {
    background-color: #FFF;
    border: 3px solid #002855;
    transform: translateX(-8px);
  }

  &.empty {
    padding: 0;
    height: 5px;
    border-radius: 5px;
    background-color: #fff;
    width: 50%;
    margin: auto;

    &::after {
      display: none;
    }
  }
}

.tabs {
  width: 100%;
  height: 100%;
  overflow: hidden;
  &__nav {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    div {
      width: 50%;
    }

    .nav-btn {
      background-color: transparent;
      border: none;
      padding: 1rem;
      color: #fff;
      font-weight: bold;
      font-size: 15px;
      display: inline-block;
      position: relative;

      &.active::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        border-radius: 4px;
        background-color: #0076CF;
      }
    }
  }

  &__panels {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .panel {
      transform: translateX(270px);
      position: absolute;
      top: 0;
      bottom: 125px;
      opacity: 0;
      transition: all .2s ease-in;
      padding: 10px 35px 15px;
      @media (min-width: $screen-md) {
        bottom: 65px;
      }

      &.active {
        width: 100%;
        z-index: 1;
        transform: translateX(0);
        opacity: 1;
        overflow: auto;
      }
    }
  }
}
