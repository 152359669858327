.form-label {
  font-family: $baseFont;
  font-style: normal;
  font-weight: normal;
  font-size: $h3-font-size;
  line-height: $headings-line-height;
  text-align: center;
  color: $simplyDark;
  margin-bottom: 1em;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 125%;
  }
}

.info-btn {
  font-family: helvetica, sans-serif;
  font-weight: bold;
  border-radius: 100%;
  vertical-align: middle;
  display: inline-block;
}

.info-btn-small {
  @extend .info-btn;
  font-size: 18px;
  color: #979797;
  border: 2px solid #c4c4c4;
  padding: 8px 11.5px;
  line-height: 18px;
}

.info-btn-large {
  @extend .info-btn;
  font-size: 36px;
  color: #002855;
  border: 4px solid #002855;
  padding: 18px 25px;
  line-height: 36px;
}
