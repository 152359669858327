@media (min-width: $screen-sm) and (max-width: $screen-md) {
  .bottom-card {
    border-radius: 3.75rem !important;
    position: relative;
  }

  .bottom-caret {
    position: relative;
    top: 0px;
  }
}

@media (min-width: $screen-md) and (max-width: $screen-lg) {
  .bottom-card {
    border-radius: 3.75rem !important;
    top: -150px;
    position: relative;
  }

  .bottom-caret {
    position: relative;
    top: -150px;
  }
}

@media (min-width: $screen-lg) and (max-width: $screen-xxxlg) {
  .bottom-card {
    border-radius: 3.75rem !important;
    position: relative;
  }

  .bottom-caret {
    position: relative;
    top: -250px;
  }
}

.bottom-text {
  font-size: 16px;
  line-height: 1.5;
}

.bottom-small-text {
  font-size: 12px;
  text-decoration: underline;
}

.aside-text-black {
  font-size: 2.4rem;
  font-family: 'Elliot-Regular', sans-serif;
  color: black;
}

.aside-text-small-black {
  font-size: 1.4rem;
  font-family: 'Elliot-Light', sans-serif;
  color: #000000;
  line-height: 1.5;
}

.individual-card {
  border-radius: 2.75rem !important;
  padding: 50px 100px 50px 100px;
}

.bottom-bg {
  background-color: #e6e7e8 !important;
}

.bottom-circle {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}
