.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 9px;
  width: 50%;
  cursor: pointer;
  &:nth-child(-n + 2) {
    background: #002855;
  }
}

.react-tabs__tab--selected {
  background: #fff;
  border-bottom: 4px solid #0076CF;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.tab-title {
  font-size: 20px;
  font-family: 'Elliot', sans-serif;
  font-weight: bold;
  font-style: normal;
  @media screen and (max-width: 1023px) and (min-width: 768px) {
    font-size: 14px;
  }
}

.top-right-circle {
  position: absolute;
  display: block;
  margin: 2em 0;
  width: 6rem;
  background-color: transparent;
  color: #f2f2f2;
  top: -72px;
  right: -32px;
  text-align: center;
  &::after {
    display: block;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color: #002855;
    content: '';
  }
  &__content {
    display: table-cell;
    padding: 1em;
    vertical-align: middle;
  }
}

.hamburger {
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 10px;
}

.investment-list {
  margin: 0;
  padding: 0; 
  list-style: none;
  &__item {
    margin-bottom: 5px; 
  }
  &__link {
    color: $charcoal;
  }
}
