@mixin card {
  border-radius: 30px;
  background-color: $white;
  padding: 20px 50px;
  flex: 0 0 auto;
  margin-bottom: 20px;
  &:last-child {flex: 1 1 auto;}

  @media (min-width: $screen-md) {
    padding-left: 35px;
    padding-right: 35px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@mixin card-label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  margin-bottom: 10px;
  @media (min-width: $screen-md) {
    margin-bottom: 10px;
  }
  @media (min-width: $screen-lg) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
  }
  .icon {
    font-size: 18px;
    color: #002855;
    padding: 5px;
  }
}

.quick-quote-section {
  padding-top: 40px;
  @media (min-width: $screen-md) {
    padding-top: 170px;
  }
}

.quick-quote {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__slides {
    .quick-quote__slide {
      display: none;
      @media (min-width: $screen-md) {
        display: block;
      }
      &.active {
        display: block;
      }
    }
    .quick-quote__slides-control {
      margin-bottom: 40px;
      text-align: center;
      display: block;
      @media (min-width: $screen-md) {
        display: none;
      }
      .control {
        width: 17px;
        height: 17px;
        background-color: #002855;
        border: none;
        border-radius: 100%;
        display: inline-block;
        padding: 0;
        margin: 5px;
        &.active {background-color: $white;}
      }
    }
  }

  .intake {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    &:first-child {
      border-bottom: 1px solid #000;
    }
    @media (min-width: $screen-md) {
      display: block;
      padding-top: 0;
      padding-bottom: 0;
      &:first-child {
        border-bottom: none;
      }
    }
    @media (min-width: $screen-lg) {
      margin-bottom: 40px;
    }

    &-label {
      flex: auto;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      margin: 0;
      @media (min-width: $screen-md) {
        margin-bottom: 20px;
        font-size: 18px;
      }
      @media (min-width: $screen-lg) {
        font-size: 22px;
      }
    }

    &-control {
      flex: 0 0 30%;
      max-width: 30%;
      @media (min-width: $screen-md) {
        flex: none;
        max-width: 100%;
      }
    }

    &-placeholder {
      display: block;
      font-weight: normal;
      font-size: 14px;
      @media (min-width: $screen-md) {
        font-size: 16px;
      }
      @media (min-width: $screen-lg) {
        font-size: 18px;
      }

    }
    &-input {
      border: 1px solid $simplyDark;
      border-radius: 0;
      color: $simplyDark;
      font-size: $h2-font-size;
      line-height: inherit;
      padding: $font-size-regular;
      width: 100%;
      max-width: 100%;
      height: auto; // 47 => 67 => 84
      @media (min-width: $screen-md) {
        padding: 0 $font-size-regular;
        font-size: 48px;
      }
      @media (min-width: $screen-lg) {
        font-size: 56px;
      }
      &::placeholder {
        color: #979797;
      }
      &.is-invalid {color: #DB4148;}
    }
    &-helper {
      font-weight: normal;
      font-size: 15px;
      line-height: 165%;
      display: block;
      @media (min-width: $screen-lg) {
        font-size: 18px;
      }
    }
  }
  &__card-row {
    margin-bottom: 10px;
    @media (min-width: $screen-md) {
      display: flex;
      justify-content: space-between;
      align-items: normal;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
  &__card {
    @include card;
    .label {
      @include card-label;
    }
  }

  &__card-split {
    @include card;
    .label {
      @include card-label;
      @media (min-width: $screen-md) {
        margin-bottom: 20px;
      }
    }

    .card-half {
      position: relative;
      padding: 20px 0;
      @media (min-width: $screen-md) {
        padding: 0 20px;
        flex: 1 1 50%;
        text-align: center;
      }
      &:nth-child(2) {
        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
        }
        &::before {
          content: "or";
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #fff;
          font-weight: bold;
          font-size: 16px;
          padding: 5px;
          z-index: 1;
          @media (min-width: $screen-md) {
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
          }
        }
        &::after {
          width: 100%;
          top: 0;
          left: 0;
          transform: translateY(-50%);
          border: 1px solid #464646;
          @media (min-width: $screen-md) {
            width: auto;
            height: 100%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

.error-helper {
  display: block;
  font-size: 14px;
  padding: 10px 0;
  &::before {
    content: "";
    margin-right: 10px;
    vertical-align: text-top;
    display: inline-block;
    background-color: #DB4148;
    border-radius: 100%;
    width: 16px;
    height: 16px;
  }
}

.stat {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: $screen-md) {
    flex-direction: column;
  }

  &-value {
    font-weight: bold;
    font-size: 50px;
    line-height: 50px;
    color: #002855;
    @media (min-width: $screen-md) {
      font-size: 64px;
      margin-bottom: 10px;
    }
  }
  .prefix {
    font-size: 26px;
    line-height: 26px;
    vertical-align: super;
    @media (min-width: $screen-md) {
      font-size: 32px;
    }
  }
  &-label {
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    color: #002855;
    padding-left: 10px;
    padding-right: 10px;

    @media (min-width: $screen-md) {
      font-size: 22px;
      margin-bottom: 10px;
    }
    small {
      font-size: 15px;
      line-height: 165%;
    }
  }
}
