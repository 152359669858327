.goback {
  font-size: 38px;
  line-height: 125%;
  align-items: center;
  text-align: center;
  color: #979797;
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  opacity: 1;
  transition: all .2s ease-in-out;
  &.active {
    opacity: 0;
    left: -15px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    z-index: 10;
    top: 6px;
  }

  .fws_dark & {
    color: $white;
  }
}
