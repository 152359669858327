.section {
  position: relative;
  &_py-5 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  &_pt-5 {
    padding-top: 80px;
  }
  &_pb-5 {
    padding-bottom: 80px;
  }
  &-divider {
    background-color: $principalBlue;
    border-radius: 90px;
    width: 100px;
    height: 4px;
  }
  &_bg-pattern {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 1)
    );
    @include bg-pattern;
  }
  &__mask-top {
    position: absolute;
    top: -1px; // fix issue with SVG sizing
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    max-width: 100%;
  }
}
