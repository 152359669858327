.stepwizard {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    color: #979797;
  }
  &__link {
    font-size: 14px;
    display: inline-block;
    padding: 5px;
    position: relative;
    @media (min-width: $screen-md) {
      padding: 15px;
      font-size: $font-size-base;
    }
  }
  &_active {
    color: #464646;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 5px;
      background-color: #002855;
      border-radius: 25px;
    }
  }
}
