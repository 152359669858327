$startColor: #0076CF;
$endColor: #002855;
$startGray: #eeeeee;
$endGray: #bababa;

@mixin content($max) {
  &__#{$max} {
    @for $i from 1 through $max {
      &:nth-child(#{$i}) {
        font-size: calc(100 * $i / $max) * 1%;
        background-color: mix(
          $startColor,
          $endColor,
          (calc(100 * $i / $max) * 1%)
        );
        &.progress-tracker__button_disabled {
          background-color: mix(
            $startGray,
            $endGray,
            (calc(100 * $i / $max) * 1%)
          );
        }
        &.progress-tracker__button_active {
          background-color: mix(
            $startColor,
            $endColor,
            (calc(100 * $i / $max) * 1%)
          );
        }
      }
    }
  }
}

.progress-tracker-wrapper {
  max-width: 250px;
  margin: auto;
  text-align: center;
}

.progress-tracker {
  margin: 1rem auto 1rem;
  width: 100%;
  text-align: center;

  @media (max-width: $screen-sm) {
    margin: .5rem auto;
  }

  &__bar {
    height: 0.5rem;
    display: flex;
    justify-content: center;
    flex-flow: row;
    transform: scale(.65);
    @media (min-width: $screen-sm) {
      transform: scale(1);
      margin: .5rem auto;
    }
  }

  &__button {
    width: 40px;
    height: 5px;
    margin: 0 1px;
    background-color: $startGray;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    transition: all 0.15s ease-in-out;

    span {
      display: none;
    }

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }


    @include content(10);
    @include content(11);
    @include content(12);
    @include content(13);
    @include content(14);
    @include content(15);
    @include content(16);
    @include content(17);
    @include content(18);
    @include content(19);
    @include content(20);
    @include content(21);
    @include content(22);
    @include content(23);
    @include content(24);
  }
}
