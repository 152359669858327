@mixin button-outline($color, $color-hover: invert($color), $color-border: $color, $active-background: $color, $active-border: $color) {
  color: $color;
  border-color: $color-border;
  background-color: transparent;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 .2rem rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }
}

.srp-button {
  text-align: center;
  vertical-align: middle;
  padding: $button-padding-y $button-padding-x;
  border: 1px solid transparent;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  box-shadow: none;
  border-radius: $border-radius-btn-default;
  background-image: none;
  background-color: transparent;
  user-select: none;
  transition: ease-out 0.15s;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus,
  &:active {
    outline: none;
    outline-offset: -2px;
    box-shadow: inset 0 3px 5px rgba($black, 0.125), 0 0 5px rgba($primary, 0.8);
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none;
    pointer-events: none;
  }

  &--outline {
    @include button-outline($black, invert($black), $simplyDusty);
  }

  &--btmbar {
    transform: translate(-50%, -175px) !important;
    white-space: nowrap;
    @media (min-width: $screen-sm) {
      transform: translate(-50%, -100px) !important;
    }
  }

  &__outline {
    color: $simplyPurple;
    border-color: $simplyPurple;
    background-color: $white;

    &:hover {
      color: $simplyPurple;
      transform: scale(1.1);
    }
  }

  &__large {
    font-size: 22px;
    padding: 15px 55px;
    border-radius: 50px;
  }

  &__sm-large {
    @media (min-width: $screen-sm) {
      font-size: 22px;
      padding: 15px 55px;
      border-radius: 50px;
    }
  }

  &__rev {
    background: $white;
    color: $secondary;
  }

  &__default {
    color: $primary;
    background-color: $white;
    border-color: $primary;
  }

  &__link {
    color: $simplyBlue;
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
    font-weight: normal;
    padding-left: 0;
    padding-right: 0;
    &--inline {
      display: inline;
      padding: 0;
      white-space: normal;
    }

    &:hover {
      text-decoration: none;
      background-color: transparent;
      box-shadow: none;
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  &__primary {
    border: 2px solid $primary;
    background-color: $primary;
    color: $white;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $white;
      background-color: $simplyPurple;
      border: 2px solid $simplyDark;
      box-shadow: 0 0 50px rgba($charcoal, 0.25);
      transform: scale(1.02);
    }
  }

  &__primary_outline {
    background-color: transparent;
    border-color: $simplyPurple;
    color: $simplyPurple;

    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
      background-color: $simplyPurple;
    }
  }

  &__secondary {
    background-color: $secondary;
    color: $white;

    &:hover,
    &:focus {
      color: $white;
      background-color: darken($secondary, 5);
      text-decoration: none;
    }
  }

  &__success {
    background-color: $success;
    color: $white;

    &:hover,
    &:focus {
      color: $white;
      background-color: darken($success, 5);
      text-decoration: none;
    }
  }

  &__outline__success {
    background-color: transparent;
    border-color: $success;
    color: $success;

    &:hover,
    &:focus {
      color: $success;
      text-decoration: none;
    }
  }

  &__outline__white {
    background-color: transparent;
    color: white;
    border-color: white;

    &:hover,
    &:focus {
      background-color: darken($lightPurple, 5);
      text-decoration: none;
    }
  }

  &__floating {
    display: flex;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 25px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    max-width: 250px;
    text-align: left;
    padding: 25px 30px;
  }

  .icon {
    // placed on the icon within the button
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
    display: inline-block;
  }
}

.button-text {
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  &:active,
  &:focus {
    background-color: transparent;
    outline: none;
  }
}
