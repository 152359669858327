.journey-container {
  max-width: 540px;
  margin: auto;
  text-align: center;
  &__mobile {
    max-width: 320px;
    margin: auto;
    text-align: center;
  }
}

.results_wrapper {
  max-width: 767px;
  margin: auto;
  text-align: center;
  @media (max-width: 767px) {
    max-width: 375px;
  }
}

.results_list {
  li {
    list-style: none;
    &::before {
      content: '\f00c';
      display: inline-block;
      font-family: 'Font Awesome 6 Free', sans-serif;
      margin-right: 5px;
      color: $simplyBlue;
    }
  }
}

.mt-6 {
  margin-top: 4.5rem;
}

.section {
  &__title {
    font-family: 'Elliot', sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 125%;
    margin-top: 10rem;
    font-size: 48px;
    color: #002855;
    @media (max-width: 767px) {
      font-size: 32px;
    }
    &__mobile {
      font-family: 'Elliot', sans-serif;
      font-weight: normal;
      font-style: normal;
      line-height: 125%;
      font-size: 32px;
      color: #002855;
    }
  }
  &__subtitle {
    font-family: 'Elliot', sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 180%;
    font-size: 20px;
    color: #002855;
    @media (max-width: 767px) {
      font-size: 17px;
      padding: 0 1.5rem 0 1.5rem;
      line-height: 125%;
    }
    &__mobile {
      font-family: 'Elliot', sans-serif;
      font-weight: normal;
      font-style: normal;
      line-height: 125%;
      font-size: 17px;
      color: #002855;
    }
  }
  &__divider {
    border: 3px solid #c4c4c4;
  }
  &__pilltext {
    font-size: 15px;
    font-family: 'Elliot', sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    &__mobile {
      font-size: 15px;
      font-family: 'Elliot', sans-serif;
      font-weight: normal;
      font-style: normal;
    }
  }
  &__pill {
    border: 2.4px solid #979797;
    box-sizing: border-box;
    border-radius: 40px;
    &__mobile {
      border: 2.4px solid #979797;
      box-sizing: border-box;
      border-radius: 40px;
    }
  }
  &__maillink {
    font-family: 'Elliot', sans-serif;
    font-family: 'Elliot', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    @media (max-width: 767px) {
      font-size: 14px;
      margin-top: 1.5rem;
    }
  }
  &__acknowledgement {
    color: #002855;
    font-family: 'Elliot', sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 24px;
    line-height: 135%;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  &__icon {
    font-size: 32px !important;
    color: #979797 !important;
    cursor: pointer;
    padding-left: 10px;
    vertical-align: middle;
    margin-bottom: 5px;
  }
}
