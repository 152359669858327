.simply-features {
  margin-bottom: -4vw;
  position: relative;

  &::after {
    // content: "";
    position: absolute;
    z-index: -1;
    top: -300px;
    left: 0;
    width: 100%;
    height: 1130px;
    background-image: url('../Images/bg-featured-section.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

.featured-tabs {
  position: relative;
  min-height: 500px;

  &__nav {
    position: absolute;
    z-index: 1;
    padding-top: 6rem;
    .nav__button {
      @media (max-width: $screen-sm) {
        font-size: 14px;
      }
    }
  }
  &__panels {
    position: absolute;
    right: 0;

    &::after {
      content: '';
      height: 100%;
      display: block;
      width: 200%;
      background-color: #fff;
      position: absolute;
      top: 0;
      right: -200%;
      z-index: 1;
    }

    .panel {
      background-color: #fff;
      padding: 100px 5vw;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      margin-left: 5rem;
      pointer-events: none;
    }
    .panel__icon {
      width: 40px;
      height: 40px;
      margin-bottom: 25px;

      @media (min-width: $screen-md) {
        width: 130px;
        height: 130px;
        margin-bottom: 50px;
      }
    }
    .show {
      position: relative;
      pointer-events: auto;
    }
  }
}
