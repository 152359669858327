@media print {
  .print-break-before {
    page-break-before: always;
  }
  .print-break-after {
    page-break-after: always;
  }
  .print-break-inside {
    page-break-inside: always;
  }
  #content,
  #page {
    width: 100%;
    margin: 0;
    float: none;
  }
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: ' (' attr(href) ')';
  }
  abbr[title]:after {
    content: ' (' attr(title) ')';
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .text-white {
    color: black !important;
  }
  iframe,
  ins {
    display: none;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap;
  }
  .embed-youtube,
  .embed-responsive {
    position: absolute;
    height: 0;
    overflow: hidden;
  }
  section {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .review-plan-section {
    padding-top: 200px !important;
  }

  .print-pt-80 {
    padding-top: 80px;
  }
}
