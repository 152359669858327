.select-css {
  display: block;
  font-size: 30px;
  font-family: 'FS Elliot Pro', sans-serif;
  line-height: 2;
  padding: 0.6em 1.4em 0.5em 0.8em;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  width: 400px;
  border: 1px solid #002855;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  appearance: none;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight: normal;
}

.navlist__item {
  .dropdown {
    &-description {
      font-size: 13px;
      font-weight: normal;
    }

    &-item {
      padding: 8px 15;
      font-weight: bold;
      text-decoration: none;
      font-size: $font-size-base * 1.125;
      @media (min-width: $screen-lg) {
        padding: 0;
        font-size: $font-size-base;
      }

      &:hover,
      &:focus,
      &:active {
        background: none;
        color: $simplyPurple;
      }
      &.active {
        background: none;
        color: $simplyPurple;
      }
    }

    &-menu {
      border: none;
      width: 100%;

      @media (min-width: $screen-sm) {
        position: absolute;
        border-radius: $border-radius-default;
        box-shadow: $box-shadow-default;
        padding: 25px;
        left: -25px !important;
        width: auto;
      }
      @media (max-width: 991px) {
        position: static !important;
        transform: none !important;
      }
    }

    &-toggle {
      padding: 0;
      color: $white;

      @media (min-width: $screen-sm) {
        padding: 0.5rem 1rem;
        color: $charcoal;
      }
    }

    &-wrapper {
      max-width: 250px;
      margin: auto;
      text-align: center;
    }
  }
}

.react-dropdown-select {
  &__control {
    width: 350px;
    font-size: 40px;
    font-family: 'Elliot', sans-serif;
    font-weight: lighter;
    font-style: normal;
    @media (max-width: 767px) {
      width: 250px;
      font-size: 16px;
    }
  }
  &-dropdown-handle {
    display: none;
  }
  &__menu {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &-dropdown {
    font-size: 20px;
    font-family: 'Elliot', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  &__option {
    border-bottom: 1px solid #979797;
  }
}
