.has-error {
  color: $danger;
}

// .srp-form
// Common forms
.srp-form-label {
  font-size: $font-size-regular;
  font-weight: bold;
  line-height: $font-size-regular;
  color: $simplyPurple;
  text-transform: uppercase;
}

.srp-form-input,
.srp-form-dropdown {
  border: 1px solid $simplyDark !important;
  border-radius: 0 !important;
  color: $simplyDark !important;
  font-size: $h2-font-size !important;
  padding: 0.675rem !important;
  height: auto;
  @media (min-width: $screen-sm) {
    padding: $font-size-regular !important;
  }
}
.srp-form-dropdown {
  padding: 0.55rem !important;
  @media (min-width: $screen-sm) {
    padding: 1rem !important;
  }
}

.srp-form-checkbox {
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 39.7346px;
  padding: 8px 12px;
  border: 5px solid transparent;
  transition: all 0.2s ease-in;
  @media (min-width: $screen-sm) {
    white-space: nowrap;
  }

  &__text {
    margin: 0;
    color: $copy;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    display: inline-block;
    padding: 0 5px 0 40px;
    position: relative;

    &::before {
      content: '';
      border: 2px solid #bababa;
      background-color: #eee;
      width: 28px;
      height: 28px;
      border-radius: 100%;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -14px;
    }
    &::after {
      content: '';
      width: 14px;
      height: 14px;
      background-color: $simplyDark;
      position: absolute;
      left: 7px;
      top: 50%;
      margin-top: -7px;
      border-radius: 100%;
      opacity: 0;
      transition: all 0.2s ease-in;
    }
  }
  &__input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    &:checked ~ .srp-form-checkbox__text::after {
      opacity: 1;
    }
  }

  &.active {
    border-color: $simplyGreen;
    .srp-form-checkbox__text {
      color: $simplyGreen;
    }
  }
}

.srp-form-toggle {
  display: flex;
  flex-flow: row;
  justify-content: center;

  @media (min-width: $screen-sm) {
    display: block;
  }

  .srp-form-checkbox {
    display: inline-block;
    flex: 50%;
    margin: 0;
    padding: 15px;
    border: 2px solid #bababa;
    cursor: pointer;
    border-radius: 0;
    z-index: 1;
    position: relative;

    @media (min-width: $screen-sm) {
      padding: 15px 25px;
    }

    .srp-form-checkbox__text {
      padding: 0;
      &::before,
      &::after {
        display: none;
      }
    }

    &:first-child {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      margin-right: -2px;
    }
    &:last-child {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      margin-left: -2px;
    }
    &.active {
      border-color: #0091DA;
      box-shadow: inset 0 0 0 2px #029d94;
      z-index: 2;
    }
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.placeholder {
  text-align: center;
  display: block;
  font-size: 20px;
  line-height: 125%;
  color: #c4c4c4;
  text-transform: uppercase;
}

.text-question {
  height: 64px;
  font-size: 32px;
  width: 100%;
  padding: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #002855;
  display: block;
  color: #979797;

  @media (min-width: $screen-md) {
    height: 108px;
    max-width: 330px;
    padding: 0.375rem 0.75rem;
    font-size: 64px;
    line-height: 1.5;
  }

  &.is-dirty {
    color: #002855;
  }

  &:focus {
    outline: none;
    border: 3px solid #0091DA;
    box-shadow: 1px 6px 5px 0px rgba(0, 0, 0, 0.25),
      0 0 2px 0px rgba(2, 157, 148, 1);
  }

  &.is-invalid {
    border-color: #dc3545;
    &:focus {
      border-color: #dc3545;
    }
  }
}
