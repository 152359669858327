.burgerbtn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  display: block;
  width: 25px;
  height: 25px;
  padding: 0;
  user-select: none;
  border: none;
  text-indent: -999em;
  background-color: transparent;
  border-radius: 0;
  transition: all 0.2s ease-in-out;

  @media (min-width: $screen-lg) {
    display: none;
  }

  &:focus {outline: none;}
  .item {
    border-radius: 3px;
    width: 100%;
    background-color: $secondary;
    height: 3px;
    display: block;
    margin: 5px 0;
    transition: all .2s ease-in;
  }

  &.close {
    position: fixed;

    .item {
      opacity: 0;
      &:nth-child(1) {
        position: absolute;
        top: 10px;
        display: block;
        margin: 0;
        background-color: #fff;
        transform: rotate(45deg);
        opacity: 1;
      }
      &:nth-child(3) {
        position: absolute;
        top: 10px;
        display: block;
        margin: 0;
        background-color: #fff;
        transform: rotate(-45deg);
        opacity: 1;
      }
    }
  }
}
