// Table
.table-fluid {
  &__thead {
    display: none;
    @media (min-width: $screen-md) {
      .heading {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
      }
    }
  }
  &__row,
  &__thead {
    @media (min-width: $screen-md) {
      display: flex;
      justify-content: space-between;
    }
  }
  &__row {
    margin-bottom: 40px;
    border-bottom: 2px solid transparent;
    @media (min-width: $screen-md) {
      border-color: #C8CCCE;
      margin: 0;
      &:last-child {
        border-bottom: 1px solid #C8CCCE;
      }
    }
    &.table-fluid_last__row {
      border-color: transparent;
      margin-bottom: 40px;
    }
  }
  &__col,
  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #C8CCCE;
    margin-bottom: -1px;
    @media (min-width: $screen-md) {
      width: 100%;
      text-align: center;
      border: none;
    }
    .heading,
    .scope {
      @media (min-width: $screen-md) {
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }
  &__col {
    margin-left: -15px;
    margin-right: -15px;
    @media (min-width: $screen-md) {
      flex: 0 1 32%;
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__label {
    display: block;
    padding: 20px 0;
    font-weight: bold;
    border-bottom: none;
    @media (min-width: $screen-md) {
      text-align: left;
      font-weight: normal;
    }
    @media (min-width: $screen-md) {
      flex: 2 1 32%;
      border-color: transparent;
    }
  }
  .table-col {
    width: 50%;
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    @media (min-width: $screen-md) {
      width: 100%;
      padding-left: 5px;
      padding-right: 5px;
      &.scope {display: none;}
    }
  }
  &_header__label {
    font-size: 16px;
  }

  &_header__row {
    background-color: rgba(196, 196, 196, 0.44);
    padding: 8px 5px;

    &.highlight {
      background-color: #edeeef;
    }
  }
}
