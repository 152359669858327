.p_container {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-flow: column;
  height: 100%;
  overflow: auto;
}

.p_container__question {
  max-width: 560px;
  text-align: center;
  margin: auto;
}

.side-panel {
  background: #002855;
}

.mt-7 {
  margin-top: 7rem;
}

.bottom-right-corner {
  position: absolute;
  bottom: 0;
  right: 0;
}

.edit-icon {
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #bababa;
}

.selection-title {
  font-size: 13px;
  font-family: 'Elliot-Regular', sans-serif;
  text-transform: uppercase;
  color: #4d4e52;
  margin-bottom: 0rem;
}

.selection-value {
  font-size: 28px;
  font-family: 'Elliot-Regular', sans-serif;
  color: #002855;
  margin-bottom: -2rem;
}

.subheading {
  font-size: 16px;
  font-family: 'Elliot', sans-serif;
  font-weight: lighter;
  font-style: normal;
  color: black;
}

.top-right-circle {
  position: absolute;
  display: block;
  margin: 2em 0;
  width: 6rem;
  background-color: transparent;
  color: #f2f2f2;
  top: -64px;
  right: -32px;
  text-align: center;
  &::after {
    display: block;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color: #002855;
    content: '';
  }
}
