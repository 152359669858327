.review-section {
  position: relative;
  padding-bottom: 30px;

  &__bg {
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: -1;
    opacity: 0.3;
  }

  &__bg-grey {
    @media (max-width: 767px) {
      background: #e5e5e5;
    }
  }

  &__text-content {
    font-size: 24px;
  }
}
