.app-panel {
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.4s;
  will-change: transform;
  width: 30%;
  opacity: 0.9;
  @media (max-width: $screen-md) {
    width: 80%;
  } 
  &-from-right {
    margin-left: auto;
  }
  &-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0);
    &.ReactModal__Overlay--after-open {
      background-color: rgba(0, 0, 0, 0.3);
      transition: background-color 0.5s;
    }
    &.ReactModal__Overlay--before-close {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.ReactModal__Content {
  outline: none;
  width: 100%;
  min-height: 100%;
  &.app-panel-from-right {
    @media (min-width: $screen-md) {
      max-width: 40%;
    }
    @media (min-width: $screen-lg) {
      max-width: 33%;
    }
  }
  &.modal-dialog {
    max-width: 90%;
    margin: auto;
    border: none;
    background-color: transparent;
    @media (min-width: $screen-md) {
      max-width: 80%;
    }
    @media (min-width: $screen-xl) {
      max-width: 66.6%;
    }
    .modal-header {
      padding: 0; 
      .close-btn {
        position: absolute;
        right: 0;
        top: 0; 
        padding: 15px;
        z-index: 1; 
        display: block;
        margin: 0;
        @media (min-width: $screen-lg) {
          padding: 25px;
        }
      } 
    }
  }
} 
.ReactModal__Overlay {
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba($simplyPurple,.5)!important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all .125s ease-in;
  transform: scale(2);
  z-index: 99999!important;

  @media (min-width: $screen-md) {
    width: auto;
    height: 100%;
    transition: all .25s ease-in;
    transform-origin: 50% 50%;
    transform: scale(1) translateX(100%);
  }
  &--after-open {
    opacity: 1;
    background-color: rgba($principalDarkBlue,.85)!important;
    transform: scale(1);
    @media (min-width: $screen-md) {
    transform: scale(1) translateX(0);
    }
  }
  &--before-close {
    opacity: 0;
    transform: scale(.5) translateX(0);
    @media (min-width: $screen-md) {
      transform: scale(2) translateX(100%);
    }
    transform: scale(1) translateX(0);
  }
}
  