.why-simply-feature-01 {
  margin-top: 0;
  @media (min-width: $screen-md) {
    margin-top: -160px;
  }
}

.retirement-table {
  &-tooltip {
    max-width: 500px;
  }
  &-row-bg {
    text-align: center;
    background: #edeeef;
    vertical-align: middle !important;
  }
  &-row {
    text-align: center;
    vertical-align: middle !important;
  }
  &-th {
    vertical-align: middle !important;
  }
}
