.equation {
  &.stacked {
    display: inline-block;
    .equation__number {
      display: block;
      margin-left: 1.5em;
    }
    .equation__operator {
      float: left;
    }
    .equation__equals {
      display: block;
      height: 0;
      border-bottom: solid 1px $white;
      overflow: hidden;
    }
  }
}
