.toast-note {
  border-radius: 4px;
  position: relative;
  padding: 14px;
  margin: 16px;
  background-color: #ffffff;
  list-style-type: none;
  align-items: center;
  box-shadow: 0 0 10px rgba($black, 0.25);

  &__content {
    flex: 1 1 auto;
    margin: 0 12px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 125%;
  }
  &__dismiss {
    background: transparent;
    border: 0;
    color: #979797;
    cursor: pointer;
    display: block;
    flex: 0 0 auto;
    padding: 0;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.toast-notes {
  top: 130px;
  position: fixed;
  right: 24px;
  width: 450px;
  z-index: 10000;
  @media (max-width: $screen-sm) {
    right: 10px;
    width: 320px;
  }
}
