.principal-card {
  margin-bottom: 20px;

  &.default {
    background-color: #fff;
    border-radius: $border-radius-default;
  }

  &.floating {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
    border: 1px solid #E1DDDD;
  }

  &__image {
    text-align: center;
    &--small {
      max-width: 350px;
      margin: 0 auto;
    }
  }

  &.roi-input {
    background-color: #fff;
    @media (min-width: $screen-xl) {
      border-radius: $border-radius-default;
    }
  }
}
