.resource-card {
  margin-bottom: 20px;
  padding: 30px 50px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: #fff;
 
}

.resource-quick-quote__container {
  background: #2d2e63;
  min-height: 134px;
  border-radius: 25px;
  padding-left: 10px;
}
