.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1030;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  border: 12px solid #002855;
}

.has-panel {
  background: linear-gradient(90deg, #fff 75%, #002855 75%);

  @media (max-width: 767px) {
    background: #fff;
  }
}

.overlay-close-btn {
  position: relative;
  margin: 1rem;
  margin-left: auto;
  width: 32px;
  height: 32px;
  text-indent: -999rem;
  background-color: transparent;
  border: 0;
  display: block;
  z-index: 10;


  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 4px;
    background-color: #979797;
    border-radius: 25px;
    top: 0;

    @media (max-width: 767px) {
      height: 18px;
    }
  }
  &--sm {
    &::before,
    &::after {
      height: 18px;
    }
  }
  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &_light {
    margin-left: auto;

    &::before,
    &::after {
      background-color: #fff;
    }
  }

  .fws_dark & {
    color: white;
    opacity: 1;
  }
}

.no-border-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1030;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}
