.fws {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  border: 6px solid #002855;
  @media (min-width: $screen-md) {
    border: 12px solid #002855;
  }

  &_dark {
    background: #002855;
    border: 10px solid #002855;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__step {
    display: none;
    width: 100%;
    height: 100%;

    &.active {
      display: flex;
      margin: auto;
    }

    .row.h-100 { // IE 11 hack
      @media (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        height: auto !important;
      }
    }

  }

  &__sidebar {
    width: 100%;
    background-color: #002855;
    position: fixed;
    overflow: hidden;
    right: 0;
    bottom: 0;
    transition: all 0.2s ease-in;
    transform: translateY(999px);
    opacity: 0;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    z-index: 1031;

    @media (min-width: $screen-md) {
      transform: translateY(0);
      opacity: 1;
      top: 0;
      max-height: 100%;
    }

    &.show {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__info-panel {
    background: #eeeeee;
    border: 1px solid #eeeeee;
    position: relative;
  }

  &__info-arrow {
    position: absolute;
    right: 5px;
    top: 5px;
    margin: 1rem;
    margin-left: auto;
    width: 40px;
    height: 40px;
    display: block;
    background-color: transparent;
    border: 0;
    @media (min-width: $screen-xl) {
      position: absolute;
      margin: 0;
      left: 10px;
      top: 60px;
    }
  }

  &__control {
    text-align: center;
    .srp-button {
      width: 70%;
      @media (min-width: $screen-sm) {
        width: auto;
      }
    }
  }
  &__control-group {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .srp-button {
      margin-left: 5px;
      margin-right: 5px;

      @media (max-width: $screen-sm) {
        flex: auto;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__controls {
    text-align: center;
    justify-content: space-between;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    @media (min-width: $screen-md) {
      display: none;
    }

    .control-btn {
      padding: 15px 10px;
      font-weight: bold;
      flex: auto;
      color: $white;
      background: $simplyPurple;
      border-radius: 0;
      border: none;
    }
  }

  &__with-sidebar {
    @media (min-width: $screen-md) {
      padding-right: 30%;

      .fws__sidebar {
        width: 30%;
      }
    }
  }
}
