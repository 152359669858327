.beginsection {
  &__wrapper-mobile {
    max-width: 364px;
    margin: auto;
    text-align: center;
  }
  &__title {
    font-family: 'Elliot', sans-serif;
    font-weight: bold;
    font-style: normal;
    line-height: 125%;
    margin-top: 10rem;
    font-size: 48px;
    color: #002855;
    &__mobile {
      font-family: 'Elliot', sans-serif;
      font-weight: normal;
      font-style: normal;
      line-height: 125%;
      margin-top: 10rem;
      font-size: 32px;
      color: #002855;
    }
  }
  &__subtitle {
    font-family: 'Elliot', sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 180%;
    font-size: 20px;
    color: #002855;
    &__mobile {
      font-family: 'Elliot', sans-serif;
      font-weight: normal;
      font-style: normal;
      line-height: 180%;
      font-size: 17px;
      color: #002855;
      &.bold {
        font-weight: bold;
      }
    }
  }
  &__divider {
    border: 3px solid #c4c4c4;
    margin-left: 100px;
    margin-right: 100px;
  }
  &__pilltext {
    font-size: 15px;
    font-family: 'Elliot', sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    &__mobile {
      font-size: 15px;
      font-family: 'Elliot', sans-serif;
      font-weight: normal;
      font-style: normal;
    }
  }
  &__pill {
    border: 2.4px solid #979797;
    box-sizing: border-box;
    border-radius: 40px;
    &__mobile {
      border: 2.4px solid #979797;
      box-sizing: border-box;
      border-radius: 40px;
    }
  }
  &__maillink {
    font-family: 'Elliot', sans-serif;
    font-family: 'Elliot', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
  }
  &__acknowledgement {
    color: #002855;
    font-family: 'Elliot', sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 24px;
    line-height: 135%;
  }
  &__icon {
    font-size: 32px !important;
    color: #979797 !important;
    cursor: pointer;
    padding-left: 10px;
    vertical-align: middle;
    margin-bottom: 5px;
  }
}

.journey-container {
  max-width: 594px;
  margin: auto;
  text-align: center;
}

.mt-6 {
  margin-top: 4.5rem;
}
