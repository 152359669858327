@keyframes slideInFullFromLeft {
  0% {
    width: 0;
    border-radius: 0;
  }
  100% {
    width: 100%;
    border-radius: $border-radius-default;
  }
}
 
@keyframes drawPath {
  10% {
    transform: scale(1);
  }
  100% {
    stroke-dashoffset: 0;
    transform: scale(1);
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(2) rotate(10deg);
    opacity: .5;
  }
  100% {
    transform: scale(1) rotate(0);
    opacity: .15; 
  }
}

@keyframes imageShadow {
  0% {
    box-shadow: 0 0 0 0 $white, 0 0 0 0 $white;
  } 
  50% {
    box-shadow: -60px -60px 0 120px $white;
  }  
  100% {
    box-shadow: -60px -60px 0 120px $white, -120px -120px 0 240px rgba($white, .25);
  }
}
