@mixin mask-arch {
  &::before {
    content: "";
    position: absolute;
    bottom: -50px;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    width: 110%;
    height: 80px;
    background-color: #fff;
    @media (min-width: $screen-md) {
      height: 160px;
      bottom: -110px;
    }
  }
}
.header-section {
  position: relative;
  padding-top: 75px;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0) 50%
  );
  @media (min-width: $screen-md) {
    padding-top: 110px;
    min-height: 600px;
  }
  @media (min-width: $screen-xlg) {
    padding-bottom: 60px;
  }
  @media (min-width: $screen-xxlg) {
    padding-bottom: 120px;
  }
  @include bg-pattern;
  &::after {
    z-index: -2;
  }
  @media (min-width: $screen-md) {
    @include mask-arch;
    &::before {z-index: 1;}
  }
  &__media {
    @media (min-width: $screen-lg) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  &__image {
    border-radius: 100%;
    animation: imageShadow .5s $easing-global forwards .25s;

    @media (min-width: $screen-md) {
      position: absolute;
      width: 600px;
      height: 600px;
      top: 80px;
      right: -250px;
      z-index: -1;
    }
    @media (min-width: $screen-lg) {
      top: -20px;
      width: 700px;
      height: 700px;
      right: -200px;
    }
    @media (min-width: $screen-xl) {
      width: 900px;
      height: 900px;
      right: -300px;
    }
    @media (min-width: $screen-xlg) {
      right: -200px;
    }
  }
  &__title {
    position: relative;
    z-index: 1;
  }
  &__copy {
    position: relative;
    z-index: 2;

  }
  &.header-section-light {
    &::after {display: none;}

  }
}

.hero-section {
  position: relative;
  padding-top: 110px;
  padding-bottom: 80px;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(255, 255, 255, .9) 15%, rgba(255, 255, 255, 0) 50%);
  @media (min-width: $screen-sm) {
    padding-top: 160px;
    padding-bottom: 120px;
  }
  @media (min-width: $screen-md) {
    padding-top: 200px;
    padding-bottom: 150px;
  }
  @media (min-width: $screen-lg) {
    padding-top: 230px;
    padding-bottom: 180px;
  }
  @media (min-width: $screen-lg) {
    padding-top: 250px;
    padding-bottom: 200px;
  }
  &__image {
    position: absolute;
    top: 0px;
    left: 50%;
    bottom: 0px;
    width: 170%;
    background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    z-index: -1;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    transform: translateX(-50%);
    overflow: hidden;
    &--offset {
      background-size: 110% auto;
      background-position: -80% center;
    }
    @media (min-width: $screen-sm) {
      background-size: cover;
      background-position: center;
    }
    @media (min-width: $screen-md) {
      background-position: center;
    }
    @media (min-width: $screen-lg) {
      background-size: auto 100%;
    }
    @media (min-width: $screen-xl) {
      background-size: 75%;
      background-position: 50%;
    }
    @media (min-width: $screen-xxlg) {
      background-size: 70%;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: .15;
      transform: scale(2);
      animation: scaleDown .5s ease-out forwards .25s;
      background-image: repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.25) 400px,
        rgba(57, 68, 78, 0.25) 650px,
        transparent 650px 760px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      );
    }
  }
  &-label {
    font-weight: bold;
    color: $white;
    position: relative;
    display: inline-block;
    z-index: 1;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 1px;
      background-color: $simplyPurple;
      z-index: -1;
      border-radius: 0;
      width: 0;
      height: 100%;
      animation: .5s cubic-bezier(.44,.01,.4,1.34) .25s slideInFullFromLeft forwards;
    }
    &.inverse {
      color: $simplyPurple;
      &::after {
        background-color: $white;
      }
    }
  }
}
